import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AsyncStates } from '../../typescript/types'
import { getAllCognitoUsers, getAllUsers, getUserById } from '../../services/users.service'
import { TPigeonUser } from '../../typescript/TPigeonUser'

const setLocalStorageUsers = (data) => {}

interface Root {
  usersSlice: {
    users: { [key: string]: TPigeonUser }
    fetchingAllUserState: AsyncStates
    fetchingAllCognitoUserState: AsyncStates
    fetchingUsersState: { [key: string]: AsyncStates }
    fetchingErrors: { [key: string]: string }
  }
}

const initialState = {
  users: {},
  fetchingAllUserState: AsyncStates.idle,
  fetchingAllCognitoUserState: AsyncStates.idle,
  fetchingUsersState: {},
  fetchingErrors: {},
}

// Async thunks below
export const fetchAllUsers = createAsyncThunk<
  any, // Return type of the payload creator
  void // First argument to the payload creator
>(
  'users/fetchAllUsers',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getAllUsers()
      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { usersSlice }: any = getState()
      if (usersSlice.fetchingAllUserState === AsyncStates.pending) {
        return false
      }
    },
  }
)

// Async thunks below
export const fetchAllCognitoUsers = createAsyncThunk<
  any, // Return type of the payload creator
  void // First argument to the payload creator
>(
  'users/fetchAllCognitoUsers',
  async (_, { rejectWithValue }) => {
    try {
      return await getAllCognitoUsers()
    } catch (e: any) {
      return rejectWithValue(e.response)
    }
  },
  {
    condition: (_, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { usersSlice }: any = getState()
      if (usersSlice.fetchingAllCognitoUserState === AsyncStates.pending) {
        return false
      }
    },
  }
)

export const fetchUserById = createAsyncThunk<
  any, // Return type of the payload creator
  string // First argument to the payload creator
>(
  'users/fetchUserAccountData',
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await getUserById(email)
      return data.user
    } catch (e: any) {
      return rejectWithValue(e?.response)
    }
  },
  {
    condition: (email, { getState }) => {
      // Prevent fetching account data if there is already a request pending.
      const { usersSlice }: any = getState()
      if (usersSlice.fetchingUsersState?.[email] === AsyncStates.pending) {
        return false
      }
    },
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.fulfilled, (state, action: any) => {
        const orig = { ...state.users }
        action.payload.forEach((user) => {
          const email = user.AccountId.toLowerCase()
          if (orig[email]) {
            orig[email] = { ...orig[email], ...user, AccountId: email }
          } else {
            orig[email] = { ...user, AccountId: email }
          }
        })
        setLocalStorageUsers(orig)
        state.users = orig
        state.fetchingAllUserState = AsyncStates.idle
      })
      .addCase(fetchAllUsers.pending, (state, action: any) => {
        state.fetchingAllUserState = AsyncStates.pending
      })
      .addCase(fetchAllUsers.rejected, (state, action: any) => {
        state.fetchingAllUserState = AsyncStates.idle
      })

    builder
      .addCase(fetchAllCognitoUsers.fulfilled, (state, action: any) => {
        const orig = { ...state.users }
        action.payload.forEach((user) => {
          const email = user.email.toLowerCase()
          if (orig[email]) {
            orig[email] = { ...orig[email], ...user, email: email }
          } else {
            orig[email] = { ...user, email: email }
          }
        })
        setLocalStorageUsers(orig)
        state.users = orig
        state.fetchingAllCognitoUserState = AsyncStates.idle
      })
      .addCase(fetchAllCognitoUsers.pending, (state, action: any) => {
        state.fetchingAllCognitoUserState = AsyncStates.pending
      })
      .addCase(fetchAllCognitoUsers.rejected, (state, action: any) => {
        state.fetchingAllCognitoUserState = AsyncStates.idle
      })

    builder
      .addCase(fetchUserById.fulfilled, (state, action) => {
        const orig = { ...state.users }
        const user = action.payload
        const email = (user?.AccountId || user?.email).toLowerCase()
        if (orig[email]) {
          orig[email] = { ...orig[email], ...user, email: email }
        } else {
          orig[email] = { ...user, email: email }
        }
        state.users = orig
        state.fetchingUsersState[action.meta?.arg] = AsyncStates.idle
      })
      .addCase(fetchUserById.pending, (state, action: any) => {
        state.fetchingUsersState[action.meta?.arg] = AsyncStates.pending
      })
      .addCase(fetchUserById.rejected, (state, action: any) => {
        state.fetchingUsersState[action.meta?.arg] = AsyncStates.error
        state.fetchingErrors[action.meta?.arg] = action.payload.data.error
      })
  },
})

// eslint-disable-next-line no-empty-pattern
export const {} = usersSlice.actions

export default usersSlice.reducer

// Below are selectors

export const selectAllUsers = (state: Root) => {
  return Object.values(state.usersSlice.users).filter((u: any) => u.email && u.AccountId)
}
export const selectUserById = (id: string) => (state: Root) => {
  return Object.values(state.usersSlice.users).find((u: any) => u.AccountId === id || u.sub === id)
}

export const selectAllReferrals = (state: Root) => {
  return Object.values(state.usersSlice.users).filter((u: any) => !u.email && u.AccountId)
}

export const selectAllUsersAsyncStates = (state: Root) => {
  return state.usersSlice.fetchingUsersState
}

export const selectAllFetchingErrors = (state: Root) => {
  return state.usersSlice.fetchingErrors
}

export const selectIsAllUsersLoading = (state: Root) =>
  state.usersSlice.fetchingAllUserState === AsyncStates.pending ||
  state.usersSlice.fetchingAllCognitoUserState === AsyncStates.pending
