import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { patchLoan } from '../../../../services/loans.service'
import { TPigeonContract } from '../../../../typescript/TPigeonContract'
import { useAppDispatch } from '../../../../redux/store'
import { fetchContractById } from '../../../../redux/slices/contractsSlice'
import { useSnackbar } from 'notistack'
import { fetchAllTiers, selectAllTiersMap } from '../../../../redux/slices/tiersSlice'
import { useSelector } from 'react-redux'
import { MenuItem, Select } from '@mui/material'

type Props = {
  open: boolean
  handleClose: () => void
  contract: TPigeonContract
}

export const UpdateContractTierModal = ({ open, handleClose, contract }: Props) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [currentTier, setCurrentTier] = useState(contract.TierDetails.TierId)
  const { enqueueSnackbar } = useSnackbar()
  const allTiers = useSelector(selectAllTiersMap)
  const currentTierVersion = contract.TierDetails.TierId
    ? allTiers?.[contract.TierDetails.TierId]?.find((t) => t.Id === contract.TierDetails.TierVersionId)
    : null

  const updateTier = async () => {
    if (!currentTier || contract.TierDetails.TierId === currentTier) {
      return
    }

    try {
      setLoading(true)
      await patchLoan(contract.ContractId, {
        updateContractTier: {
          tierId: currentTier,
          tierVersionId: allTiers[currentTier][0].Id,
        },
      })
      await dispatch(fetchContractById(contract.ContractId)).unwrap()
      enqueueSnackbar('Updated!', { variant: 'success' })
      handleClose()
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Error', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!open) {
      setCurrentTier(contract.TierDetails.TierId)
    }
  }, [open, contract])

  useEffect(() => {
    dispatch(fetchAllTiers())

    //eslint-disable-next-line
  }, [])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <DialogTitle className={'text-center w-full'}>Update Tier</DialogTitle>
      <DialogContent>
        <p>
          Select new tier.
          <br /> Current Tier:{' '}
          <b>
            {currentTierVersion?.Name} - V{currentTierVersion?.Version}
          </b>
        </p>
        <Select
          fullWidth={true}
          value={currentTier}
          onChange={({ target }) => setCurrentTier(parseInt(target.value + ''))}
        >
          {Object.values(allTiers).map((versionArr) => {
            const tierVersion = versionArr[0]

            return (
              <MenuItem key={tierVersion.TierId} value={tierVersion.TierId}>
                {tierVersion.Name} - V{tierVersion.Version}
              </MenuItem>
            )
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant={'contained'}
          color={'success'}
          onClick={updateTier}
          disabled={currentTier === contract.TierDetails.TierId}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
