import { Typography } from '@mui/material'
import React from 'react'
import classes from './LabelAndValue.module.scss'

const LabelAndValue = ({
  label,
  value,
  padding = 'default',
  margin = 'default',
}: {
  label: string
  value: string | number | React.ReactNode
  padding?: string
  margin?: string
}) => {
  return (
    <div className={classes.labelAndValue} style={{ padding, margin }}>
      <Typography className={classes.label}>{label}:</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  )
}
export default LabelAndValue
