import { Auth } from 'aws-amplify'
import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'

export const NewPasswordForm = ({ email, cognitoUser }) => {
  const [form, setForm] = useState({ password: '', confirmPassword: '' })
  const [formErrors, setFormErrors] = useState({
    password: '',
    confirmPassword: '',
  })
  const specialCharacters = '!@#$%^&*()_+-=[]{};\':"|,.<>/?~\\'
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleNewPassword = async (e) => {
    e.preventDefault()

    if (cognitoUser) {
      setLoading(true)
      try {
        await Auth.completeNewPassword(
          cognitoUser, // the Cognito User Object
          form.password, // the new password
          {
            //email,
          }
        )
        enqueueSnackbar('Password updated', {
          variant: 'success',
        })
        navigate('/')
      } catch (e) {
        enqueueSnackbar('Failed to update password', {
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const validatePassword = (password) => {
    if (!password) {
      return ''
    }

    let hasUpper = false
    let hasLower = false
    let hasNumber = false
    let hasSpecial = false
    let error = ''
    for (let letter of password) {
      if (!hasLower && letter === letter.toLowerCase()) {
        hasLower = true
      }

      if (!hasUpper && letter === letter.toUpperCase() && letter.toLowerCase() !== letter.toUpperCase()) {
        hasUpper = true
      }

      if (!hasNumber && !isNaN(parseInt(letter))) {
        hasNumber = true
      }

      if (!hasSpecial && specialCharacters.includes(letter)) {
        hasSpecial = true
      }
    }

    if (!hasUpper) {
      error = 'Password must have at least one upper case letter'
    } else if (!hasNumber) {
      error = 'Password must have at least one number'
    } else if (!hasSpecial) {
      error = 'Password must have at least one special character (ex. !,#.)'
    } else if (!hasLower) {
      error = 'Password must have at least one lower case letter'
    } else if (password.length < 8) {
      error = 'Password must be at minimum 8 characters long'
    }

    return error
  }

  const handleFormChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'confirmPassword') {
      if (value && value !== form.password) {
        setFormErrors({
          ...formErrors,
          confirmPassword: 'Does not match password',
        })
      } else {
        setFormErrors({
          ...formErrors,
          confirmPassword: '',
        })
      }
    } else if (name === 'password') {
      setFormErrors({ ...formErrors, [name]: validatePassword(value) })
    }

    setForm({ ...form, [name]: value })
  }

  return (
    <div>
      <h2 className={'text-center text-3xl mt-0'}>New Password</h2>
      <form onSubmit={handleNewPassword} className={'flex flex-col gap-4'}>
        <TextField
          label={'Password'}
          value={form.password}
          onChange={handleFormChange}
          name={'password'}
          required={true}
          type={'password'}
          error={Boolean(formErrors.password)}
          helperText={formErrors.password}
        />
        <TextField
          label={'Confirm Password'}
          value={form.confirmPassword}
          onChange={handleFormChange}
          name={'confirmPassword'}
          required={true}
          type={'password'}
          error={Boolean(formErrors.confirmPassword)}
          helperText={formErrors.confirmPassword}
        />

        <LoadingButton loading={loading} type={'submit'} variant={'contained'}>
          {loading ? 'Saving...' : 'Submit'}
        </LoadingButton>
      </form>
    </div>
  )
}
