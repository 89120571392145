import React from 'react'
import DEAD_PIGEON from '../assets/images/pigeon-dead.svg'
import Button from '@mui/material/Button'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

function UnauthorizedPage() {
  const navigate = useNavigate()

  const logout = async () => {
    await Auth.signOut()
    navigate('/login')
  }

  return (
    <div className={'container-fluid text-center pigeon-text-color shift-parent-down'}>
      <div className="row align-items-center justify-content-center" style={{ minHeight: '80vh' }}>
        <div className="col-auto">
          <img src={DEAD_PIGEON} alt={'Pigeon Not Found'} style={{ width: '40vw', height: '40vh' }} />
          <h1 className="pigeon-text-color mt-1 mb-3">You are not authorized to view this app</h1>
          <h3 className="pigeon-text-color mt-1 mb-3">
            If you belive you should, please reach out to your administrator.
          </h3>

          <Button className={'mt-8'} variant={'contained'} onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UnauthorizedPage
