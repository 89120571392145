import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AsyncStates } from '../../typescript/types'
import { getAllSubscriptionTiers, getTierByTierId } from '../../services/tiers.service'
import { TPigeonTier } from '../../typescript/TPigeonTier'

type root = {
  tiersSlice: {
    tiers: { [key: number]: TPigeonTier[] }
    allTiersAsyncStatus: AsyncStates
    tiersAsyncStatus: { [key: string]: AsyncStates }
    tierAsyncErrors: { [key: string]: any }
  }
}

const initialState = {
  tiers: {},
  allTiersAsyncStatus: AsyncStates.idle,
  tiersAsyncStatus: {},
  tierAsyncErrors: {},
}

// Async thunks below
export const fetchAllTiers = createAsyncThunk(
  'tiers/fetchAllTiers',
  async (_, thunkAPI) => {
    try {
      const { data } = await getAllSubscriptionTiers()
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e?.response?.data || e)
    }
  },
  {
    condition: (status, { getState, extra }) => {
      // Prevent fetching Tiers by status if there is already a request pending.
      const { tiersSlice }: any = getState()
      if (tiersSlice.allTiersAsyncStatus === AsyncStates.pending) {
        return false
      }
    },
  }
)

export const fetchTierById = createAsyncThunk(
  'tiers/fetchTierById',
  async (id: number, thunkAPI) => {
    try {
      const { data }: any = await getTierByTierId(id)
      return { data: data, id }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e?.response?.data || e)
    }
  },
  {
    condition: (id, { getState, extra }) => {
      // Prevent fetching tier by status if there is already a request pending.
      const { tiersSlice }: any = getState()
      if (tiersSlice.tierAsyncStatus?.[id] === AsyncStates.pending) {
        return false
      }
    },
  }
)

const tiersSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAllTiers.fulfilled, (state, action: any) => {
        const tiers = action.payload

        state.tiers = tiers.reduce((acc, tier) => {
          const temp = { ...acc }
          if (temp[tier.TierId]) {
            temp[tier.TierId].push(tier)
          } else {
            temp[tier.TierId] = [tier]
          }

          return { ...acc, ...temp }
        }, {})

        Object.values(state.tiers).forEach((versions: any) => versions.sort((a, b) => b.CreatedAt - a.CreatedAt))

        state.allTiersAsyncStatus = AsyncStates.idle
      })
      .addCase(fetchAllTiers.rejected, (state, action: any) => {
        state.allTiersAsyncStatus = AsyncStates.error
      })
      .addCase(fetchAllTiers.pending, (state, action: any) => {
        state.allTiersAsyncStatus = AsyncStates.pending
      })

    builder
      .addCase(fetchTierById.fulfilled, (state, action: any) => {
        const allTiers: any = { ...state.tiers }
        if (allTiers[action.payload.id]) {
          allTiers[action.payload.id] = action.payload.data
        } else {
          allTiers[action.payload.id] = action.payload.data
        }

        state.tiers = allTiers
        state.tiersAsyncStatus[action.meta?.arg] = AsyncStates.idle
      })
      .addCase(fetchTierById.rejected, (state, action: any) => {
        state.tiersAsyncStatus[action.meta?.arg] = AsyncStates.error
        state.tierAsyncErrors[action.meta?.arg] = action?.payload?.error
      })
      .addCase(fetchTierById.pending, (state, action: any) => {
        state.tiersAsyncStatus[action.meta?.arg] = AsyncStates.pending
        state.tierAsyncErrors[action.meta?.arg] = null
      })
  },
})

// Actions
// eslint-disable-next-line no-empty-pattern
export const {} = tiersSlice.actions

// Below are selectors
export const selectAllTiersArray = (state: root) => {
  return Object.keys(state.tiersSlice.tiers).map((key) => ({
    id: key,
    versions: [...state.tiersSlice.tiers?.[key]].sort((a, b) => b?.Version - a?.Version),
  }))
}

export const selectAllTiersMap = (state: root) => state.tiersSlice.tiers

export default tiersSlice.reducer
