/*
 * Used to configure/manage AWS resources
 * */

import Amplify, { Hub } from 'aws-amplify'
import { COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from '../constants'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID,
  },
})

const listener = (data) => {
  const event = data.payload.event
  switch (event) {
    case 'signIn':
      break
    case 'signUp':
      break
    case 'signOut':
      break
    case 'signIn_failure':
      break
    case 'tokenRefresh':
      break
    case 'tokenRefresh_failure':
      break
    case 'configured':
      break
    default:
    // placeholder
  }
}

Hub.listen('auth', listener)
