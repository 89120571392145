import React, { useEffect, useState } from 'react'
import { Card, IconButton } from '@mui/material'
import classes from './ValueCardDisplay.module.scss'
import { DescriptionOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { selectDidInitiallyDownload } from '../redux/slices/appSlice'

type Props = {
  label: string
  value: string[] | number[]
  size?: 'sm' | 'lg'
  hideIndex?: boolean
  description?: string | React.ReactNode
}

const init = (value, label = '') => {
  const storedValue = window.localStorage.getItem(label)

  if (storedValue) {
    return JSON.parse(storedValue)
  } else {
    return value
  }
}

export const ListCardDisplay = ({ label, value, size = 'sm', hideIndex = false, description = '' }: Props) => {
  const [hovering, setHovering] = useState(false)
  const [displayValue, setDisplayValue] = useState(init(value, label))
  const isDataDownloaded = useSelector(selectDidInitiallyDownload)

  useEffect(() => {
    if (isDataDownloaded) {
      setDisplayValue(value)
      window.localStorage.setItem(label, JSON.stringify(value))
    }
    // eslint-disable-next-line
  }, [value, isDataDownloaded])

  return (
    <Card
      className={`relative ${size === 'lg' ? 'p-4' : 'p-4'} w-[400px]`}
      raised={size === 'lg'}
      onMouseLeave={() => setHovering(false)}
    >
      {description && hovering ? <div className={classes.descriptionContainer}>{description}</div> : ''}
      <label className={`font-bold text-slate-600 ${size === 'lg' ? 'text-lg' : 'text-sm'}`}>{label}</label>
      <div className={'flex flex-col mt-2'}>
        {displayValue.map((value, index) => {
          return (
            <span className={'flex gap-2 text-lg'} key={index}>
              {!hideIndex && <b className={'font-light'}>{index + 1}.</b>} <label>{value}</label>
            </span>
          )
        })}
      </div>
      {description && (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation()
            setHovering(true)
          }}
          className={classes.descriptionButton}
        >
          <DescriptionOutlined />
        </IconButton>
      )}
    </Card>
  )
}
