import axios from 'axios'
import { getCognitoIdToken } from '../utils/authenticate'
import { PIGEON_API_URL } from '../constants'

/*
 * This interceptor will add the cognito token to any request that is going to Pigeon loans api
 * */
axios.interceptors.request.use(
  async (request) => {
    if (request.url && request.url.includes(PIGEON_API_URL)) {
      try {
        const tokenObj: any = await getCognitoIdToken()
        request.headers = {
          ...request.headers,
          ...tokenObj,
        }
      } catch (e: any) {
        window.location.reload()
      }
    }

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)
