import { Navigate, Outlet, RouteProps, useLocation, useNavigate } from 'react-router-dom'
import { TGenericObject } from '../typescript/types'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { isAuthenticated } from '../utils/authenticate'

export const PrivateRoute = (props: RouteProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [authenticated, setAuthenticated] = useState<TGenericObject | false | null>(null)

  const authorized: boolean = !!authenticated && authenticated?.attributes?.['custom:role'] === 'admin'

  useEffect(() => {
    const checkAuth = async () => {
      const isAuth = await isAuthenticated()

      if (!isAuth) {
        setAuthenticated(false)
        return
      }

      const result = await Auth.currentUserInfo()
      setAuthenticated(result)
    }

    checkAuth().then()
  }, [])

  useEffect(() => {
    if (authenticated === false) {
      let state = {}

      if (location.pathname.includes('/users/') || location.pathname.includes('/contracts/')) {
        state = { pathname: location.pathname }
      }

      navigate('/login', { state })
    }

    //eslint-disable-next-line
  }, [authenticated])

  if (authenticated === null) {
    return <h1>...Loading</h1>
  }

  return authenticated ? authorized ? <Outlet /> : <Navigate to="/unauthorized" /> : null
}
