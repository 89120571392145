import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllLoans, getLoanById } from '../../services/loans.service'
import { AsyncStates } from '../../typescript/types'
import { TPigeonContract } from '../../typescript/TPigeonContract'

interface Root {
  contractsSlice: {
    contracts: { [key: string]: TPigeonContract }
    allContractAsyncStatus: AsyncStates
    contractAsyncStatus: { [key: string]: AsyncStates }
    contractAsyncErrors: { [key: string]: string }
  }
}

const initialState = {
  contracts: {},
  allContractAsyncStatus: AsyncStates.idle,
  contractAsyncStatus: {},
  contractAsyncErrors: {},
}

// Async thunks below
export const fetchAllContracts = createAsyncThunk(
  'loans/fetchContracts',
  async (_, thunkAPI) => {
    try {
      const { data } = await getAllLoans()
      return data
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e?.response?.data || e)
    }
  },
  {
    condition: (status, { getState, extra }) => {
      // Prevent fetching contracts by status if there is already a request pending.
      const { contractsSlice }: any = getState()
      if (contractsSlice.allContractAsyncStatus === AsyncStates.pending) {
        return false
      }
    },
  }
)

export const fetchContractById = createAsyncThunk(
  'loans/fetchContractById',
  async (id: number, thunkAPI) => {
    try {
      const { data }: any = await getLoanById(id)
      return { data: data, id }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e?.response?.data || e)
    }
  },
  {
    condition: (contractId, { getState }) => {
      // Prevent fetching contracts by status if there is already a request pending.
      const { contractsSlice }: any = getState()
      if (contractsSlice.contractAsyncStatus?.[contractId] === AsyncStates.pending) {
        return false
      }
    },
  }
)

const contractsSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAllContracts.fulfilled, (state, action: any) => {
        const contracts = action.payload
        state.contracts = contracts.reduce((acc, contract) => {
          return { ...acc, [contract.ContractId]: contract }
        }, {})
        state.allContractAsyncStatus = AsyncStates.idle
      })
      .addCase(fetchAllContracts.rejected, (state) => {
        state.allContractAsyncStatus = AsyncStates.error
      })
      .addCase(fetchAllContracts.pending, (state) => {
        state.allContractAsyncStatus = AsyncStates.pending
      })

    builder
      .addCase(fetchContractById.fulfilled, (state, action: any) => {
        const allContracts = { ...state.contracts }
        if (allContracts[action.payload.id]) {
          allContracts[action.payload.id] = {
            ...allContracts[action.payload.id],
            ...action.payload.data,
          }
        } else {
          allContracts[action.payload.id] = action.payload.data
        }

        state.contracts = allContracts
        state.contractAsyncStatus[action.meta?.arg] = AsyncStates.idle
      })
      .addCase(fetchContractById.rejected, (state, action: any) => {
        state.contractAsyncStatus[action.meta?.arg] = AsyncStates.error
        state.contractAsyncErrors[action.meta?.arg] = action?.payload?.error
      })
      .addCase(fetchContractById.pending, (state, action: any) => {
        state.contractAsyncStatus[action.meta?.arg] = AsyncStates.pending
        state.contractAsyncErrors[action.meta?.arg] = null
      })
  },
})

// Actions
// eslint-disable-next-line no-empty-pattern
export const {} = contractsSlice.actions

// Below are selectors
export const selectAllContracts = (state: Root) => Object.values(state.contractsSlice.contracts)

export const selectIsAllContractsLoading = (state: Root) =>
  state.contractsSlice.allContractAsyncStatus === AsyncStates.pending

export const selectContractAsyncStatus = (state: Root) => state.contractsSlice.contractAsyncStatus

export const selectAllContractErrors = (state: Root) => state.contractsSlice.contractAsyncErrors

export default contractsSlice.reducer
