import React from 'react'

import sharedClasses from '../SectionCards.module.scss'

type Props = {
  header: string | React.ReactNode
  subHeader?: string | React.ReactNode
  children: React.ReactNode
  containerClassName?: HTMLDivElement['className']
  detailsClassName?: HTMLDivElement['className']
  action?: React.ReactNode
}

export const SimpleSectionCard = ({
  header,
  children,
  containerClassName = '',
  subHeader = '',
  detailsClassName = '',
  action = null,
}: Props) => {
  return (
    <div className={`${sharedClasses.container} ${containerClassName}`}>
      <div className={sharedClasses.headerContainer}>
        <div className={'text-left'}>
          <h3 className={sharedClasses.headerTitle}>{header}</h3>
          {subHeader && <p className={sharedClasses.subHeader}>{subHeader}</p>}
        </div>
        {action}
      </div>
      <div className={`${sharedClasses.detailsContainer} ${detailsClassName}`}>{children}</div>
    </div>
  )
}
