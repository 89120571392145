import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import classes from './Sections.module.scss'
import { useNavigate } from 'react-router-dom'

export const CounterpartiesSection = ({ title, subheader = '', counterparties }) => {
  const navigate = useNavigate()
  return (
    <SimpleSectionCard
      header={title}
      subHeader={subheader}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {counterparties.map((cp, index) => {
        return (
          <div key={cp?.email || index} className={'flex flex-row gap-3 hover:bg-gray-200'}>
            <span onClick={() => navigate(`/users/${cp?.email}`)} className={`${classes.sectionFieldLarge} textLink`}>
              {cp?.email}
            </span>
            <span>{cp?.name}</span>
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
