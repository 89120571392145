// Basics
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

// AWS Cognito
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID

// Pigeon URLS
export const PIGEON_API_URL: string = process.env.REACT_APP_PIGEON_API_URL!

// Stripe
export const STRIPE_BASE_URL: string = process.env.REACT_APP_STRIPE_BASE_URL!

// One minute in milliseconds
export const ONE_MIN_IN_MS = 60 * 1000
