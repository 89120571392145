/*
 * Given a number, format to 2 decimal and $ sign
 * */
import { STRIPE_BASE_URL } from '../constants'

export const formatMoney = (amount: string | number, includeSign = true) => {
  if (amount === null || amount === undefined) {
    return ''
  }
  const total = amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  return includeSign ? '$' + total : total
}

export const getLastOffer = (contract) => {
  if (contract?.Offers?.length) {
    return contract.Offers[contract.Offers.length - 1]
  } else {
    return {}
  }
}

export const contractWasActive = (contract) => {
  if (contract.ContractStatus === 'Inactive') {
    return contract.AcknowledgeTerms === 'Acknowledged' && contract.ClosureReason.toLowerCase() !== 'fraud'
  } else return contract.ContractStatus === 'Active'
}

/*
 * Given a list of contracts and a target email. Will find all the counterparties email and name from contracts
 * */
export const getSetOfCounterParties = (contracts: any[], targetEmail: string) => {
  const counterParties = contracts.map((contract) => {
    const isBorrower = contract.Borrower === targetEmail

    return {
      email: isBorrower ? contract.Lender : contract.Borrower,
      name: isBorrower ? contract.LenderName : contract.BorrowerName,
    }
  })
  const emailSet = new Set()

  counterParties.forEach((cp) => emailSet.add(cp.email))

  return Array.from(emailSet).map((email) => counterParties.find((cp) => cp.email === email))
}

/*
 * Given a payment object, return the stripe url
 * */
export const getStripeRedirect = (payment) => {
  let url = `${STRIPE_BASE_URL}/payments/${payment.id}`

  if (payment.source === 'session' || payment.source === 'subscription' || payment.id.includes('ba_')) {
    url = `${STRIPE_BASE_URL}/customers?email=${payment.senderEmail}`
  }

  window.open(url, '_blank')
}

export const getClosureReason = (r, parseOther = true) => {
  if (!r) {
    return ''
  }

  const reason = r?.toLowerCase()

  if (reason === 'canceled' || reason.includes('@')) {
    return 'unknown'
  } else if (reason === 'stale') {
    return 'auto-canceled: inactivity'
  } else if (reason.includes('canceled by')) {
    return 'before reasons'
  } else if (reason.includes('other')) {
    if (parseOther) {
      return 'other'
    } else {
      return reason
    }
  } else if (reason === 'fraud') {
    return 'suspicious activity'
  }

  return reason
}
