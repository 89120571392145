import { PIGEON_API_URL } from '../constants'
import axios from 'axios'

export const getAllLoans = async () => {
  let paginate = true
  const contracts: any[] = []
  const url = PIGEON_API_URL + '/admin/contracts?page_size=2500'

  let temp_url = url
  while (paginate) {
    const { data }: any = await axios.get(temp_url)

    for (let contract of data.contracts) {
      contracts.push(contract)
    }

    if (data.index) {
      temp_url = url + `&last_index=${data.index}`
    } else {
      paginate = false
    }
  }

  return { data: contracts.filter((c) => c?.Description !== 'test') }
}

export const getLoanById = async (id: number) => {
  const url = PIGEON_API_URL + '/admin/contracts/' + id
  return axios.get(url)
}

export const patchLoan = async (id, payload) => {
  const url = `${PIGEON_API_URL}/admin/contracts/${id}`
  return axios.patch(url, payload)
}
