import React, { useEffect, useState } from 'react'
import { Card, IconButton } from '@mui/material'
import classes from './ValueCardDisplay.module.scss'
import { DescriptionOutlined } from '@mui/icons-material'
import { selectDidInitiallyDownload } from '../redux/slices/appSlice'
import { useSelector } from 'react-redux'

type Props = {
  label: string
  value: string | number
  onClick?: () => void
  size?: 'sm' | 'lg'
  description?: string | React.ReactNode
}

export const ValueCardDisplay = ({ label, value, onClick = undefined, size = 'sm', description = '' }: Props) => {
  const [hovering, setHovering] = useState(false)
  const [displayValue, setDisplayValue] = useState(label ? window.localStorage.getItem(label) || value : value)
  const isDataDownloaded = useSelector(selectDidInitiallyDownload)

  useEffect(() => {
    if (isDataDownloaded) {
      setDisplayValue(value)
      window.localStorage.setItem(label, String(value))
    }
    // eslint-disable-next-line
  }, [value, isDataDownloaded])

  return (
    <Card
      className={`relative ${onClick ? 'cursor-pointer' : ''} ${size === 'lg' ? 'p-4' : 'p-4'} w-[400px] h-min`}
      onClick={onClick}
      raised={size === 'lg'}
      onMouseLeave={() => setHovering(false)}
    >
      {description && hovering ? <div className={classes.descriptionContainer}>{description}</div> : ''}
      <label className={`font-bold text-slate-600 ${size === 'lg' ? 'text-lg' : 'text-sm'}`}>{label}</label>
      <h3 className={`m-0 ${size === 'lg' ? 'text-6xl' : 'text-3xl'}`}>{displayValue}</h3>
      {description && (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation()
            setHovering(true)
          }}
          className={classes.descriptionButton}
        >
          <DescriptionOutlined />
        </IconButton>
      )}
    </Card>
  )
}
