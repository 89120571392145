import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal'
import { updatePatchTier } from '../../../../services/tiers.service'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from '../../../../redux/store'
import { fetchTierById, selectAllTiersMap } from '../../../../redux/slices/tiersSlice'
import { TierDetailsSection } from './TierDetailsSection'
import { LoanConstraintsSection } from './LoanConstraintsSection'
import { Box, Chip, Divider } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { selectAllContracts } from '../../../../redux/slices/contractsSlice'

export const DisplayTierDetails = ({ tierId, handleEditClick }) => {
  const allTiers = useSelector(selectAllTiersMap)
  const allContracts = useSelector(selectAllContracts)
  const tierVersions = allTiers[tierId]
  const [activateModalOpen, setActivateModalOpen] = useState(false)
  const [activateLoading, setActivateLoading] = useState(false)
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const [archiveLoading, setArchiveLoading] = useState(false)
  const [tierVersion, setTierVersion] = useState<any>({})
  const [draftVersion, setDraftVersion] = useState<any>(null)
  const [pageSize, setPageSize] = useState(50)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Name',
      description: 'Name of Tier Version',
      sortable: true,
      width: 160,
    },
    {
      field: 'Version',
      headerName: 'Version',
      sortable: true,
      width: 160,
    },
    {
      field: 'Status',
      headerName: 'Status',
      description: '',
      sortable: true,
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        const tier = params.row
        let className = ''
        const status = tier.Active ? 'Active' : tier.ArchivedAt ? 'Archived' : 'Draft'
        if (status === 'Active') {
          className = 'text-green-600'
        } else if (status === 'Archived') {
          className = 'text-orange-600'
        }

        return (
          <span className={className}>
            <b>{status}</b>
          </span>
        )
      },
    },
    {
      field: 'TotalLoans',
      headerName: 'Total Loans',
      description: 'How many loans have/had this version',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        const tier = params.row

        return allContracts.filter((c) => {
          return c.TierDetails.TierVersionId === tier.Id
        }).length
      },
    },
    {
      field: 'CreatedBy',
      headerName: 'Created By',
      sortable: true,
      width: 180,
    },
    {
      field: 'CreatedAt',
      headerName: 'Created At',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams) => new Date(params.value * 1000).toLocaleDateString(),
    },
    {
      field: 'ActivatedAt',
      headerName: 'Activated At',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? new Date(params.value * 1000).toLocaleDateString() : '-',
    },
    {
      field: 'ActivatedBy',
      headerName: 'Activated By',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    },
    {
      field: 'ArchivedAt',
      headerName: 'Archived At',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value ? new Date(params.value * 1000).toLocaleDateString() : '-',
    },
    {
      field: 'ArchivedBy',
      headerName: 'Archived By',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    },
  ]

  const handleActivate = async (openModal: boolean) => {
    if (openModal) {
      setActivateModalOpen(true)
      return
    }

    if (activateModalOpen) {
      setActivateModalOpen(false)
    }

    setActivateLoading(true)

    try {
      await updatePatchTier(draftVersion.Id, { activate: true })
      enqueueSnackbar('Activated successfully', { variant: 'success' })
      dispatch(fetchTierById(tierVersion.TierId))
    } catch (e) {
      enqueueSnackbar('Failed to activate', { variant: 'error' })
    } finally {
      setActivateLoading(false)
    }
  }

  const handleArchive = async (openModal: boolean) => {
    if (openModal) {
      setArchiveModalOpen(true)
      return
    }

    if (archiveModalOpen) {
      setArchiveModalOpen(false)
    }

    setArchiveLoading(true)

    try {
      await updatePatchTier(tierVersion.Id, { archive: true })
      enqueueSnackbar('Archived successfully', { variant: 'success' })
      dispatch(fetchTierById(tierVersion.TierId))
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Failed to archive', { variant: 'error' })
    } finally {
      setArchiveLoading(false)
    }
  }

  const init = () => {
    // find active version
    const activeVersion = tierVersions.find((version) => version.Active)

    // Find draft version
    const tempDraftVersion = !tierVersions[0].Active && !tierVersions[0].ArchivedAt ? tierVersions[0] : null

    // Find last archived version
    const archivedVersion = tierVersions.find((version) => !version.Active && version.ArchivedAt)

    setTierVersion(activeVersion || archivedVersion || tempDraftVersion || tierVersions[0])

    if (tempDraftVersion) {
      setDraftVersion(tempDraftVersion)
    } else if (draftVersion) {
      setDraftVersion(null)
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [tierVersions])

  return (
    <>
      <div className={'flex flex-col gap-4'}>
        <div className={'flex gap-4'}>
          <Button variant={'contained'} onClick={handleEditClick}>
            {draftVersion ? 'Edit Draft' : tierVersion.Active || tierVersion.CreatedAt ? 'Create New Version' : 'Edit'}
          </Button>

          {(!tierVersion.Active && !tierVersion.ArchivedAt) || draftVersion ? (
            <LoadingButton
              color={'success'}
              variant={'contained'}
              loading={activateLoading}
              onClick={() => handleActivate(true)}
            >
              {tierVersion.Active || tierVersion.ArchivedAt ? 'Activate Draft' : 'Activate'}
            </LoadingButton>
          ) : null}

          {tierVersion.Active && (
            <LoadingButton
              color={'error'}
              variant={'contained'}
              loading={archiveLoading}
              onClick={() => handleArchive(true)}
            >
              Archive
            </LoadingButton>
          )}
        </div>

        <div className={'flex gap-4 flex-wrap'}>
          <TierDetailsSection tierVersion={tierVersion} sectionTitle={'Tier Details'} />

          <LoanConstraintsSection tierVersion={tierVersion} sectionTitle={'Loan Constraints'} />
        </div>

        {draftVersion && tierVersions.length > 1 && (
          <>
            <Divider textAlign={'left'}>
              <Chip label={'Draft Details Below'} />
            </Divider>
            <div className={'flex gap-4 flex-wrap'}>
              <TierDetailsSection tierVersion={draftVersion} sectionTitle={'Draft Tier Details'} />
              <LoanConstraintsSection tierVersion={draftVersion} sectionTitle={'Draft Loan Constraints'} />
            </div>
          </>
        )}

        <Box className={'m-auto'} sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={tierVersions}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.Id}
          />
        </Box>
      </div>

      <SimpleModal
        open={activateModalOpen}
        title={'Activate Drafted Tier'}
        bodyText={
          <>
            <b>Would you like to activate this tier?</b>
            <br />
            {tierVersion.Active && <span>Current active version will be archived.</span>}
            {!tierVersion.Active && (
              <span>
                You will no longer be able to edit this version. You will be required to create a new version of this
                tier to make any future edits.
              </span>
            )}
          </>
        }
        handleClose={() => setActivateModalOpen(false)}
        onCancel={() => setActivateModalOpen(false)}
        onSuccess={() => handleActivate(false)}
        successButtonText={'Yes'}
      />
      <SimpleModal
        open={archiveModalOpen}
        title={'Archive Tier'}
        bodyText={
          <>
            <b>Would you like to archive this tier?</b>
            <br /> By archiving this tier, new users will no longer be able to subscribe. Any current users with this
            tier will continue to be subscribed to it.
          </>
        }
        handleClose={() => setArchiveModalOpen(false)}
        onCancel={() => setArchiveModalOpen(false)}
        onSuccess={() => handleArchive(false)}
        successButtonText={'Yes'}
      />
    </>
  )
}
