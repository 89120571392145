import { useEffect } from 'react'
import { Layout } from '../../components/Layout/Layout'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  fetchUserById,
  selectAllFetchingErrors,
  selectAllUsersAsyncStates,
  selectUserById,
} from '../../redux/slices/usersSlice'
import { useAppDispatch } from '../../redux/store'
import { AsyncStates } from '../../typescript/types'
import { SimpleSectionCard } from '../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue'
import { BankSection } from './Sections/BankSection'
import { CardsSection } from './Sections/CardsSection'
import { PayoutSection } from './Sections/PayoutSection'
import { LoansSection } from './Sections/LoansSection'
import { getSetOfCounterParties } from '../../utils/utils'
import { CounterpartiesSection } from './Sections/CounterpartiesSection'
import { Alert } from '@mui/material'
import { STRIPE_BASE_URL } from '../../constants'
import { PigeonPointsSection } from './Sections/PigeonPointsSection'
import { UserPaymentsSection } from './Sections/UserPaymentsSection'
import { UserDepositsSection } from './Sections/UserDepositsSection'
import Button from '@mui/material/Button'
import { UserActions } from './Sections/UserActions'

export const UserDetailPage = () => {
  const dispatch = useAppDispatch()
  const fetchingUsersErrors = useSelector(selectAllFetchingErrors)
  const allUsersAsyncStates = useSelector(selectAllUsersAsyncStates)
  const { id } = useParams()
  const user = useSelector(selectUserById(id!))
  const fetchingError = fetchingUsersErrors[id!]

  const handleLiveSessionClick = () => {
    window.open(
      `https://app.livesession.io/app/18cb423c/sessions?filters=((must:visitor.email:((string:((operator:eq):(values:((0:${user?.AccountId}))))))::))&pagination=((0:creation_timestamp_desc:20))&dateRange=((past_7_days::))`,
      '_blank'
    )
  }

  const handleIntercomClick = () => {
    const filters = `{"predicates":[{"attribute":"role","comparison":"eq","type":"role","value":"user_role"},{"attribute":"email","comparison":"eq","type":"string","value":"${user?.email}"}]}`

    const url = `https://app.intercom.com/a/apps/ukwp8fu2/users/segments/all-users:${btoa(filters)}`

    window.open(url, '_blank')
  }

  useEffect(() => {
    dispatch(fetchUserById(id!))

    //eslint-disable-next-line
  }, [id])

  if (allUsersAsyncStates[id!] === AsyncStates.error) {
    return (
      <div className={'container mx-auto p-20'}>
        Failed to fetch user with ID: <b>{id!}</b>
        <br />
        <br />
        <Alert variant={'filled'} severity={'error'}>
          {fetchingError}
        </Alert>
        <Link className={'mx-auto block w-max mt-10 text-xl'} to={'/users'}>
          Back to Users
        </Link>
      </div>
    )
  }

  if (!user) {
    return <div>...Loading</div>
  }

  return (
    <Layout localLoading={allUsersAsyncStates[id!] === AsyncStates.pending}>
      <div className={'flex justify-between items-center px-8'}>
        <h1>User Details</h1>

        <div className={'flex flex-row items-center'}>
          <Button size={'small'} variant={'contained'} onClick={handleLiveSessionClick} className={'mx-2'}>
            Live Session
          </Button>
          <Button size={'small'} variant={'contained'} onClick={handleIntercomClick} className={'mx-2'}>
            Intercom
          </Button>
        </div>
      </div>

      <UserActions user={user} />

      <div className={'p-4 gap-4 flex flex-row flex-wrap'}>
        <SimpleSectionCard
          header={'Basic Data'}
          detailsClassName={'pt-2 gap-1 flex flex-col height-max'}
          containerClassName={'h-max'}
        >
          <LabelAndValue label={'Email'} value={user?.AccountId || user?.email} />
          <LabelAndValue label={'Cognito Sub'} value={user?.sub} />
          <LabelAndValue label={'First Name'} value={user?.given_name} />
          <LabelAndValue label={'Last Name'} value={user?.family_name} />
          <LabelAndValue
            label={'Joined'}
            value={user?.UserCreateDate && new Date(user?.UserCreateDate).toLocaleDateString()}
          />
          <LabelAndValue
            label={'Cognito Enabled'}
            value={
              <span className={`${user?.Enabled ? 'text-green-600' : 'text-red-500'} font-bold`}>
                {user?.Enabled ? 'Enabled' : 'Disabled'}
              </span>
            }
          />
          <LabelAndValue label={'Email Confirmed'} value={user?.email_verified} />
          <LabelAndValue
            label={'Last Login'}
            value={user?.LastLogin && new Date(user?.LastLogin * 1000).toLocaleDateString()}
          />
          <LabelAndValue label={'Pigeon Points'} value={user?.PigeonPoints} />
          <LabelAndValue label={'Heard About us?'} value={user?.Metadata?.hearAboutUs || 'unknown'} />
        </SimpleSectionCard>

        <SimpleSectionCard
          header={'Demographics'}
          subHeader={'Self reported details'}
          detailsClassName={'pt-2 gap-1 flex flex-col'}
          containerClassName={'h-max'}
        >
          <LabelAndValue label={'Birth Year'} value={user?.['custom:birthYear']} />
          <LabelAndValue label={'Ethnicity'} value={user?.['custom:ethnicity']} />
          <LabelAndValue label={'Income Range'} value={user?.['custom:personalIncome']} />
          <LabelAndValue label={'Credit Range'} value={user?.CreditRange} />
          <LabelAndValue label={'Gender'} value={user?.['custom:gender']} />
        </SimpleSectionCard>

        <SimpleSectionCard
          header={'Stripe'}
          subHeader={'Connect and Customer Account'}
          detailsClassName={'pt-2 gap-1 flex flex-col'}
          containerClassName={'h-max'}
        >
          <LabelAndValue
            label={'Customer ID'}
            value={
              <a href={`${STRIPE_BASE_URL}/customers/${user?.StripeCustomerId}`} rel="noreferrer" target={'_blank'}>
                {user?.StripeCustomerId}
              </a>
            }
          />
          <LabelAndValue
            label={'Connect ID'}
            value={
              <a href={`${STRIPE_BASE_URL}/connect/accounts/${user?.StripeId}`} rel="noreferrer" target={'_blank'}>
                {user?.StripeId}
              </a>
            }
          />
          <LabelAndValue label={'In-app Payments Enabled'} value={user?.AccountActive ? 'yes' : 'no'} />
          <LabelAndValue
            label={'Connect account needs attention'}
            value={user?.ConnectAccount?.actionNeeded ? 'yes' : 'no'}
          />
          <LabelAndValue label={'Connect account type'} value={user?.ConnectAccount?.type} />
        </SimpleSectionCard>

        <BankSection
          title={`Banks (${user?.CustomerAccount?.banks?.length || 0})`}
          subheader={'Banks that can make payments'}
          banks={user?.CustomerAccount?.banks}
        />

        <CardsSection
          title={`Cards (${user?.CustomerAccount?.cards?.length || 0})`}
          subheader={'Cards that can make payments'}
          cards={user?.CustomerAccount?.cards}
        />

        <PayoutSection
          title={`Payout Account`}
          subheader={'Bank or Debit card that can receive deposits.'}
          payouts={user?.ConnectAccount?.external_accounts?.data}
        />

        <LoansSection
          title={`Loans (${Object.values(user?.Loans || []).length})`}
          subheader={'All of the users loans'}
          loans={Object.values(user?.Loans || []).length ? Object.values(user.Loans) : []}
          userEmail={user?.AccountId?.toLowerCase()}
        />

        <CounterpartiesSection
          title={'Counterparties'}
          subheader={`Counterparties that ${user.given_name} has/had a loan with.`}
          counterparties={getSetOfCounterParties(Object.values(user?.Loans || {}), user.AccountId)}
        />
        <PigeonPointsSection pigeonPoints={user?.PigeonPointsPapertrail || []} user={user} />

        <UserPaymentsSection
          userEmail={user?.AccountId?.toLowerCase()}
          loans={Object.values(user?.Loans || []).length ? Object.values(user.Loans) : []}
        />

        <UserDepositsSection
          userEmail={user?.AccountId?.toLowerCase()}
          loans={Object.values(user?.Loans || []).length ? Object.values(user.Loans) : []}
        />
      </div>
    </Layout>
  )
}
