import { contractWasActive, formatMoney, getClosureReason } from './utils'
import { TPigeonContract } from '../typescript/TPigeonContract'
import { TPigeonTier } from '../typescript/TPigeonTier'

export const getContractsByStatus = (status: 'Pending' | 'Active' | 'Inactive', contracts: any[]) => {
  return contracts.filter((contract: any) => {
    return contract?.ContractStatus === status
  })
}

/*
 * Returns all loans that are or once was active
 * */
export const getAllLoansActivated = (contracts: any[]) => {
  return contracts.filter((contract) => contractWasActive(contract))
}

/*
 * Returns all active premium loans
 * */
export const getActivePremiumLoans = (contracts: TPigeonContract[]) => {
  return contracts.filter(
    (contract) =>
      contract.ContractStatus === 'Active' && contract.TierDetails && contract.TierDetails.PaymentStatus !== 'free'
  )
}

/*
 * Returns all premium loans, including those now inactive
 * */
export const getAllPremiumLoans = (contracts: TPigeonContract[]) => {
  return contracts.filter(
    (contract) =>
      (contract.ContractStatus === 'Active' || contractWasActive(contract)) &&
      contract.TierDetails &&
      (contract.TierDetails.PaymentStatus === 'complete' || contract.TierDetails.PaymentStatus === 'active')
  )
}

/*
 * Returns all active premium loans payment types
 * */
export const getActivePremiumLoansPaymentTypeBucket = (contracts: TPigeonContract[]) => {
  const countBucket = {
    monthly: 0,
    'one-time': 0,
  }

  contracts
    .filter(
      (contract) => contractWasActive(contract) && contract.TierDetails && contract.TierDetails.PaymentStatus !== 'free'
    )
    .forEach((contract) => {
      if (contract?.TierDetails?.PaymentType) {
        countBucket[contract.TierDetails.PaymentType]++
      }
    })

  const totalCount = Object.values(countBucket).reduce((acc, count) => acc + count, 0)

  return Object.keys(countBucket)
    .sort((reasonA, reasonB) => {
      return countBucket[reasonB] - countBucket[reasonA]
    })
    .map((reason) => {
      return `${reason} - ${Math.round((countBucket[reason] / (totalCount || 1)) * 100)}% (${countBucket[reason]})`
    }, [])
}

/*
 * Returns all active premium loans tiers
 * */
export const getActivePremiumLoansTiersBucket = (
  contracts: TPigeonContract[],
  allTiers: { [key: string]: TPigeonTier[] }
) => {
  const countBucket: { [key: string]: number } = {}

  contracts
    .filter(
      (contract) => contractWasActive(contract) && contract.TierDetails && contract.TierDetails.PaymentStatus !== 'free'
    )
    .forEach((contract) => {
      if (contract?.TierDetails?.TierId) {
        const tierName = allTiers[contract?.TierDetails?.TierId]?.[0].Name || contract?.TierDetails?.TierId
        if (countBucket[tierName]) {
          countBucket[tierName]++
        } else {
          countBucket[tierName] = 1
        }
      }
    })

  const totalCount: any = Object.values(countBucket).reduce((acc: number, count: number) => acc + count, 0)

  return Object.keys(countBucket)
    .sort((reasonA, reasonB) => {
      return countBucket[reasonB] - countBucket[reasonA]
    })
    .map((reason) => {
      return `${reason} - ${Math.round((countBucket[reason] / totalCount) * 100)}% (${countBucket[reason]})`
    }, [])
}

/*
 * Returns who paid for active premium loans
 * */
export const getActivePremiumLoansPayerBucket = (contracts: TPigeonContract[]) => {
  const countBucket: { [key: string]: number } = {}

  contracts
    .filter(
      (contract) => contractWasActive(contract) && contract.TierDetails && contract.TierDetails.PaymentStatus !== 'free'
    )
    .forEach((contract) => {
      if (contract?.TierDetails?.PaidBy) {
        if (countBucket[contract?.TierDetails?.PaidBy]) {
          countBucket[contract?.TierDetails?.PaidBy]++
        } else {
          countBucket[contract?.TierDetails?.PaidBy] = 1
        }
      }
    })

  const totalCount: any = Object.values(countBucket).reduce((acc: number, count: number) => acc + count, 0)

  return Object.keys(countBucket)
    .sort((reasonA, reasonB) => {
      return countBucket[reasonB] - countBucket[reasonA]
    })
    .map((reason) => {
      return `${reason} - ${Math.round((countBucket[reason] / totalCount) * 100)}% (${countBucket[reason]})`
    }, [])
}

/*
 * Gets the average principal for all active loans
 * */
export const getAveragePrincipal = (contracts = []) => {
  if (!Array.isArray(contracts) || !contracts.length) {
    return 0
  }

  const activeContracts = getContractsByStatus('Active', contracts)

  const principalSum = activeContracts.reduce((sum, contract) => sum + parseFloat(contract.Principal), 0)

  return principalSum / activeContracts.length
}

/*
 * Gets the average loan duration for all active loans
 * */
export const getAverageLoanDuration = (contracts = []) => {
  if (!Array.isArray(contracts) || !contracts.length) {
    return 0
  }

  const activeContracts = getContractsByStatus('Active', contracts)

  const durationSum = activeContracts.reduce((sum, contract) => sum + parseInt(contract.InitialMonths), 0)

  return (durationSum / activeContracts.length).toFixed(1)
}

/*
 * Gets the average loan interest rate for all active loans
 * */
export const getAverageLoanInterestRate = (contracts = []) => {
  if (!Array.isArray(contracts) || !contracts.length) {
    return 0
  }

  const activeContracts = getContractsByStatus('Active', contracts)

  const interestSum = activeContracts.reduce((sum, contract) => sum + parseInt(contract.InterestRate), 0)

  return (interestSum / activeContracts.length).toFixed(2)
}

/*
 * Gets the reasons and number of loans for creating loans
 * */
export const getLoanCreatedReasons: (contracts: any[]) => any[] = (contracts = []) => {
  if (!Array.isArray(contracts) || !contracts.length) {
    return []
  }

  const reasonsCount: { [key: string]: number } = {}

  const activeContracts = getOnceActiveContracts(contracts)

  activeContracts.forEach((contract: TPigeonContract) => {
    if (contract?.LoanMetadata?.LoanReason) {
      let reason = contract?.LoanMetadata?.LoanReason.toLowerCase()

      if (reason === 'student') {
        reason = 'student expenses'
      } else if (reason === 'large_purchase') {
        reason = 'large purchase'
      }

      if (reason in reasonsCount) {
        reasonsCount[reason]++
      } else {
        reasonsCount[reason] = 1
      }
    }
  })

  const totalReasons = Object.values(reasonsCount).reduce((acc, count) => acc + count, 0)

  return Object.keys(reasonsCount)
    .sort((reasonA, reasonB) => {
      return reasonsCount[reasonB] - reasonsCount[reasonA]
    })
    .map((reason) => {
      return `${reason} - ${Math.round((reasonsCount[reason] / totalReasons) * 100)}% (${reasonsCount[reason]})`
    }, [])
}

/*
 * Gets the reasons and number of loans for canceling loans
 * */
export const getLoanCancelReasons: (contracts: any[]) => any[] = (contracts = []) => {
  if (!Array.isArray(contracts) || !contracts.length) {
    return []
  }

  const reasonsCount: { [key: string]: number } = {}

  const inactiveContracts = getContractsByStatus('Inactive', contracts)

  inactiveContracts.forEach((contract) => {
    if (contract?.ClosureReason) {
      let reason = getClosureReason(contract?.ClosureReason?.toLowerCase())

      if (reason in reasonsCount) {
        reasonsCount[reason]++
      } else {
        reasonsCount[reason] = 1
      }
    }
  })

  const totalReasons = Object.values(reasonsCount).reduce((acc, count) => acc + count, 0)

  return Object.keys(reasonsCount)
    .sort((reasonA, reasonB) => {
      return reasonsCount[reasonB] - reasonsCount[reasonA]
    })
    .map((reason) => {
      return `${reason} - ${Math.round((reasonsCount[reason] / totalReasons) * 100)}% (${reasonsCount[reason]})`
    }, [])
}

/*
 * Gets loans count in various principal bucket sizes
 * */
export const getLoanPrincipalBuckets: (allContracts: any[]) => any[] = (allContracts = []) => {
  if (!Array.isArray(allContracts) || !allContracts.length) {
    return []
  }
  const contracts = getContractsByStatus('Active', allContracts)
  const results: string[] = []
  const bucketsMap = {
    50000: 0,
    25000: 0,
    15000: 0,
    10000: 0,
    5000: 0,
    2500: 0,
    1000: 0,
    500: 0,
    0: 0,
  }

  let bucketKeys = Object.keys(bucketsMap).reverse()

  contracts.forEach((contract) => {
    let allocated = false

    bucketKeys.forEach((key) => {
      const bucketAmount = parseInt(key)
      if (!allocated && parseFloat(contract.Principal) >= bucketAmount) {
        bucketsMap[key]++
        allocated = true
      }
    })
  })

  bucketKeys.forEach((key, index) => {
    if (index === 0) {
      results.push(`${formatMoney(parseInt(key))}+ : ${bucketsMap[key]}`)
    } else {
      const previous = parseInt(bucketKeys[index - 1]) - 1
      results.push(`${formatMoney(parseInt(key))} - ${formatMoney(previous)}: ${bucketsMap[key]}`)
    }
  })

  return results
}

/*
 * Gets sum of all active/once active loan principals
 * */
export const getTotalMoneyMoved = (contracts = [], allActive = true) => {
  if (!Array.isArray(contracts) || !contracts.length) {
    return 0
  }

  const allContracts = allActive ? getOnceActiveContracts(contracts) : getContractsByStatus('Active', contracts)

  return allContracts.reduce((sum, contract) => sum + parseInt(contract.Principal), 0)
}

/*
 * Gets number of active loans that are in default
 * */
export const getLoansInDefault = (contracts: any[]) => {
  return contracts.filter((contract: any) => {
    return (
      contract?.ContractStatus === 'Active' &&
      contract.RemainingMonths === 0 &&
      contract.LoanExtension?.extension !== 'accepted'
    )
  })
}

// ===============================

/*
 * Gets all current and previously active contracts
 * */
const getOnceActiveContracts = (contracts: any[]) => {
  return contracts.filter((contract) => {
    if (contract.ContractStatus === 'Active') {
      return contract
    } else if (contract.ContractStatus === 'Inactive') {
      return contractWasActive(contract)
    } else {
      return false
    }
  })
}
