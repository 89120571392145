import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../../components/LabelAndValue/LabelAndValue'
import { TPigeonContract } from '../../../typescript/TPigeonContract'
import { STRIPE_BASE_URL } from '../../../constants'

type Props = {
  contract: TPigeonContract
}

export const PremiumLoanDetailsSection = ({ contract }: Props) => {
  const TierDetails = contract.TierDetails
  return (
    <SimpleSectionCard
      header={'Premium Loan Details'}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      <div>
        <LabelAndValue label={'Payment Status'} value={TierDetails.PaymentStatus} />
        <LabelAndValue label={'Payment Type'} value={TierDetails.PaymentType} />
        <LabelAndValue label={'Tier Version Id'} value={TierDetails.TierVersionId} />
        <LabelAndValue label={'Paid By'} value={TierDetails.PaidBy} />
        {TierDetails.PaymentType === 'monthly' && (
          <LabelAndValue
            label={'Subscription Id'}
            value={
              <a
                href={`${STRIPE_BASE_URL}/subscriptions/${TierDetails.SubscriptionId}`}
                rel="noreferrer"
                target={'_blank'}
              >
                {TierDetails.SubscriptionId}
              </a>
            }
          />
        )}
      </div>
    </SimpleSectionCard>
  )
}
