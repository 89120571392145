import { useSelector } from 'react-redux'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { selectAllContracts } from '../../redux/slices/contractsSlice'
import { formatMoney, getClosureReason, getLastOffer } from '../../utils/utils'
import { Layout } from '../../components/Layout/Layout'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { TPigeonContract } from '../../typescript/TPigeonContract'
import { selectAllTiersMap } from '../../redux/slices/tiersSlice'

export const ContractsPage = () => {
  const rows = useSelector(selectAllContracts)
  const [pageSize, setPageSize] = useState(50)
  const allTiers = useSelector(selectAllTiersMap)

  let columns: GridColDef[] = [
    {
      field: 'Principal',
      headerName: 'Principal',
      description: 'Principal of the loan',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        const lastOffer = getLastOffer(params.row)
        const value = params.row.Principal === -1 ? lastOffer?.Principal : params.row.Principal
        return value ? parseFloat(value) : 0
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => (params.value ? formatMoney(params.value) : '0'),
    },
    {
      field: 'SuggestedMonthlyPayment',
      headerName: 'Monthly Payment',
      description: 'Suggested monthly payment',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        const lastOffer = getLastOffer(params.row)
        return lastOffer.MonthlyPayment
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => (params.value ? formatMoney(params.value) : '0'),
    },
    {
      field: 'Borrower',
      headerName: 'Borrower Email',
      width: 180,
    },
    {
      field: 'Lender',
      headerName: 'Lender Email',
      width: 180,
    },
    {
      field: 'ContractStatus',
      headerName: 'Status',
      description: 'Status of loans',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        if (
          params.value === 'Active' &&
          params.row.RemainingMonths === 0 &&
          params.row?.LoanExtension?.extension !== 'accepted'
        ) {
          return 'In Default'
        } else {
          return params.value
        }
      },
    },
    {
      field: 'PremiumLoan',
      headerName: 'Is Premium Loan',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.row.TierDetails?.PaymentStatus !== 'free',
    },
    {
      field: 'PremiumTierName',
      headerName: 'Tier',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        const tierId = params.row.TierDetails?.TierId
        if (tierId) {
          return allTiers[tierId][0].Name
        } else {
          return 'Free'
        }
      },
    },
    {
      field: 'imported',
      headerName: 'Imported',
      description: 'If the loan is imported',
      sortable: true,
      width: 100,
      valueGetter: (params: GridValueGetterParams) => {
        const lastOffer = getLastOffer(params.row)
        return params.row.LoanMetadata?.ImportedLoan || lastOffer?.Imported ? 'yes' : 'no'
      },
    },
    {
      field: 'StartDate',
      headerName: 'Date Started',
      description: 'Start Date of loan',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value && params.value !== -1 ? new Date(params.value * 1000).toDateString() : '',
    },
    {
      field: 'CreatedDate',
      headerName: 'Date Created',
      description: 'Created Date of loan',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.Offers?.length ? params.row?.Offers?.[0]?.Date : null
      },
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value ? new Date(params.value * 1000).toDateString() : '',
    },
    {
      field: 'ClosedOn',
      headerName: 'Date Closed',
      description: 'Closed Date of loan',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value ? new Date(params.value * 1000).toDateString() : '',
    },
    {
      field: 'ContractProtection',
      headerName: 'Contract Protection',
      description: 'Has contract protection enabled',
      sortable: true,
      width: 180,
      valueGetter: (params: GridValueGetterParams<TPigeonContract>) => {
        const contract: TPigeonContract | any = params.row

        if (contract.ContractProtection) {
          if (contract.LoanMetadata.CustomContractAddedAt) {
            return 'yes, custom'
          } else {
            return 'yes'
          }
        } else {
          return 'no'
        }
      },
    },
    {
      field: 'ExpectedEndDate',
      headerName: 'Expected End Date',
      description: 'This field shows the expected end date of the loan',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value && params.value !== -1 ? new Date(params.value * 1000).toDateString() : '',
    },
    {
      field: 'InterestRate',
      headerName: 'Interest Rate',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) => (params.value === -1 ? '' : params.value + '%'),
    },
    {
      field: 'InterestType',
      headerName: 'Interest Type',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        const lastOffer = getLastOffer(params.row)

        return (
          (params.row?.InterestType ? params.row?.InterestType || 'simple' : lastOffer?.InterestType || 'simple') +
          ' Interest'
        )
      },
    },
    {
      field: 'InitialMonths',
      headerName: 'Duration (Months)',
      description: 'Initial length of loan',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === -1 ? '' : params.value + ' months',
    },
    {
      field: 'RemainingMonths',
      headerName: 'Remaining Months',
      description: 'Remaining months on the life of the loan',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === -1 ? '' : params.value + ' months',
    },
    {
      field: 'NextPaymentDate',
      headerName: 'Next Payment Date',
      description: 'Next expected payment date',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value && params.value !== -1 ? new Date(params.value * 1000).toDateString() : '',
    },
    {
      field: 'CustomRepaymentSchedule',
      headerName: 'Custom Repayments',
      description: 'Does this loan have a custom repayment schedule?',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams<number>) => !!params.row.CustomRepaymentSchedule?.length,
      valueFormatter: (params: GridValueFormatterParams<number>) => (params.value ? 'yes' : 'no'),
    },
    {
      field: 'Payments',
      headerName: 'Payments Confirmed',
      description: 'Total payments confirmed',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.row.Payments?.length || 0,
    },
    {
      field: 'PendingPayments',
      headerName: 'Pending Payments',
      description: 'Payments made but not confirmed.',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.FreebirdQueue?.length || 0 + params.row.StripePaymentsQueue?.length || 0,
    },
    {
      field: 'AutoPay',
      headerName: 'Auto-pay',
      description: 'Whether or not auto pay is enabled.',
      sortable: true,
      width: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.SubscriptionDetails?.attribute_values?.subscriptionId ? 'Enabled' : 'no',
    },
    {
      field: 'ClosureReason',
      headerName: 'Closure Reason',
      description: 'Reason for loan being inactive',
      sortable: true,
      width: 160,
      valueGetter: (params) => {
        return getClosureReason(params?.value)
      },
    },
    {
      field: 'LoanReason',
      headerName: 'Loan Created Reason',
      description: 'Reason for loan being created',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        return params?.row?.LoanMetadata?.LoanReason
      },
    },
    {
      field: 'LoanExtension',
      headerName: 'Extended',
      description: 'If loan was/is extended',
      sortable: true,
      width: 160,
      valueGetter: (params) => {
        const loanExtensions = params.row.LoanExtension?.attribute_values
        if (loanExtensions?.extension === 'accepted') {
          return 'yes'
        } else if (loanExtensions?.extension?.includes('@')) {
          return 'requested'
        }
        return loanExtensions?.extension || 'no'
      },
    },
    {
      field: 'PayoffAmount',
      headerName: 'Payoff Amount',
      description: 'How much left the loan has to payoff',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === -1) {
          return formatMoney(0)
        } else {
          return formatMoney(params.value)
        }
      },
    },
  ]

  columns.sort((a: any, b: any) => (a?.headerName < b?.headerName ? -1 : 1))

  columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 180,
      valueGetter: (params: GridValueGetterParams) => params.row.ContractId,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Link className={'textLink'} to={`/contracts/${params.value}`}>
          {params.value}
        </Link>
      ),
    },
    ...columns,
  ]

  return (
    <Layout>
      <div className={'w-full h-fit flex flex-col py-4 px-12 box-border '}>
        <div className={'flex justify-between items-center'}>
          <h1 className={'text-5xl'}>Contracts</h1>
        </div>

        <Box className={'m-auto'} sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
            disableSelectionOnClick
            getRowId={(row) => row.ContractId}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </div>
    </Layout>
  )
}
