import { Chip, Divider, TextField } from '@mui/material'
import { useState } from 'react'
import Button from '@mui/material/Button'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import {
  createTier,
  createTierVersion,
  deleteTierVersion,
  updatePutTierVersion,
} from '../../../../services/tiers.service'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../redux/store'
import { fetchAllTiers, fetchTierById } from '../../../../redux/slices/tiersSlice'

type Props = {
  tierVersion?: any
  onCancelEditMode: () => void
}

export const TierForm = ({ tierVersion = null, onCancelEditMode }: Props) => {
  const [form, setForm] = useState(init())
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value

    if (name === 'fullPaymentDiscountPercentage') {
      value = parseInt(value)
    }

    setForm({ ...form, [name]: value })
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setLoading(true)

    try {
      // Remove empty values
      const payload = Object.keys(form).reduce((acc, key) => {
        return { ...acc, [key]: form[key] }
      }, {})

      let savedTier
      if (tierVersion?.Id) {
        if (tierVersion.Active || tierVersion.ArchivedAt) {
          savedTier = await createTierVersion(payload, tierVersion.TierId)
        } else {
          savedTier = await updatePutTierVersion(tierVersion.Id, payload)
        }
      } else {
        savedTier = await createTier(payload)
      }

      await dispatch(fetchTierById(savedTier.TierId)).unwrap()

      enqueueSnackbar('Tier saved successfully', { variant: 'success' })

      if (!tierVersion?.Id) {
        navigate(`/subscription-tiers/${savedTier.TierId}`)
      } else {
        onCancelEditMode()
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Failed to save tier', {
        variant: 'error',
        autoHideDuration: 3000,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (ev) => {
    ev.preventDefault()

    if (!tierVersion.Id || tierVersion.Active || tierVersion.ArchivedAt) {
      return
    }

    setDeleteLoading(true)

    try {
      await deleteTierVersion(tierVersion.Id)

      enqueueSnackbar('Tier version deleted', { variant: 'success' })

      if (tierVersion?.Version) {
        await dispatch(fetchTierById(tierVersion.TierId)).unwrap()
        onCancelEditMode()
      } else {
        await dispatch(fetchAllTiers()).unwrap()
        navigate(`/subscription-tiers`)
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Failed to delete tier', {
        variant: 'error',
      })
      onCancelEditMode()
    } finally {
      setDeleteLoading(false)
    }
  }

  function init(reset = false) {
    const initData = {
      id: tierVersion?.Id || '',
      tierId: tierVersion?.TierId || '',
      version: tierVersion?.Active ? tierVersion.Version + 1 : tierVersion?.Version || '1',
      name: tierVersion?.Name || '',
      monthlyAmount: tierVersion?.MonthlyAmount ?? '',
      fullPaymentDiscountPercentage: tierVersion?.FullPaymentDiscountPercentage ?? '',
      description: tierVersion?.Description || '',
      maxPrincipal: tierVersion?.MaxLoanPrincipal ?? '',
      maxDurationInMonths: tierVersion?.MaxDurationInMonths ?? '',
    }

    if (reset) {
      setForm(initData)
    }

    return initData
  }

  return (
    <form onSubmit={handleSubmit} className={'max-w-[600px] w-full gap-4 flex flex-col'}>
      <Divider textAlign={'left'}>
        <Chip label={'Pricing Details Below'} />
      </Divider>

      <h4 className={'m-0'}>
        Version: {tierVersion?.Active || tierVersion?.ArchivedAt ? tierVersion.Version + 1 : tierVersion?.Version || 1}
      </h4>

      <TextField
        size={'small'}
        fullWidth={true}
        label="Tier Name"
        variant="outlined"
        name={'name'}
        value={form.name}
        onChange={handleChange}
        required
      />
      <TextField
        size={'small'}
        fullWidth={true}
        label="Monthly Amount"
        variant="outlined"
        type={'number'}
        InputProps={{ inputProps: { min: 0, step: 0.01 } }}
        name={'monthlyAmount'}
        value={form.monthlyAmount}
        onChange={handleChange}
        required
      />
      <TextField
        size={'small'}
        fullWidth={true}
        label="Full Payment Discount Percentage"
        variant="outlined"
        type={'number'}
        InputProps={{ inputProps: { min: 0, step: 1 } }}
        name={'fullPaymentDiscountPercentage'}
        value={form.fullPaymentDiscountPercentage}
        onChange={handleChange}
        helperText={
          'This discount will be applied to the product of the monthly amount and the loan duration in months'
        }
        required
      />

      <Divider textAlign={'left'}>
        <Chip label={'Loan Constraints Details Below'} />
      </Divider>

      <TextField
        size={'small'}
        fullWidth={true}
        label="Max Loan Principle Amount"
        variant="outlined"
        type={'number'}
        InputProps={{ inputProps: { min: 0 } }}
        name={'maxPrincipal'}
        value={form.maxPrincipal}
        onChange={handleChange}
        helperText={'Empty will default to $500,000'}
      />

      <TextField
        size={'small'}
        fullWidth={true}
        label="Max Duration in Months"
        variant="outlined"
        type={'number'}
        InputProps={{ inputProps: { min: 0 } }}
        name={'maxDurationInMonths'}
        value={form.maxDurationInMonths}
        onChange={handleChange}
        helperText={'Empty will default to 84 months'}
      />

      <Divider textAlign={'left'}>
        <Chip label={'Additional Details Below'} />
      </Divider>

      <TextField
        multiline={true}
        onChange={handleChange}
        name={'description'}
        value={form.description}
        placeholder="Description of this tier, internal purposes"
        minRows={2}
      />
      <div className={'flex flex-col gap-4'}>
        <div className={'flex gap-2'}>
          <Button variant={'outlined'} className={'flex-1'} onClick={() => onCancelEditMode()} disabled={loading}>
            Cancel
          </Button>
          <Button variant={'outlined'} className={'flex-1'} onClick={() => init(true)} disabled={loading}>
            Reset
          </Button>
        </div>
        <div className={'flex gap-2'}>
          {tierVersion?.Id && !tierVersion?.Active && !tierVersion?.ArchivedAt ? (
            <LoadingButton
              fullWidth={true}
              onClick={handleDelete}
              type={'button'}
              variant={'contained'}
              className={'flex-1 '}
              color={'error'}
              loading={deleteLoading}
            >
              Delete
            </LoadingButton>
          ) : null}
          <LoadingButton
            fullWidth={true}
            type={'submit'}
            variant={'contained'}
            className={'flex-1'}
            color={'success'}
            loading={loading}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </form>
  )
}
