export type TGenericObject = {
  [key: string]: any
}

export enum AsyncStates {
  pending = 'pending',
  idle = 'idle',
  error = 'error',
}

export enum InterestTypes {
  // Intercom carousel ids
  SIMPLE = 'simple',
  MONTHLY_COMPOUND = 'compound',
}
