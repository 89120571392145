import { PIGEON_API_URL } from '../constants'
import axios from 'axios'

export const getAllUsers = async () => {
  const users: any[] = []

  let paginationSize = 5000
  let paginate = true
  const base_url = PIGEON_API_URL + `/admin/users`
  let lastIndex: string | undefined
  while (paginate) {
    let data

    try {
      let temp_url = `${base_url}?page_size=${paginationSize}${lastIndex ? `&last_index=${lastIndex}` : ''}`
      const res: any = await axios.get(temp_url)
      data = res.data
    } catch (e) {
      paginationSize = paginationSize / 2
      let temp_url = `${base_url}?page_size=${paginationSize}${lastIndex ? `&last_index=${lastIndex}` : ''}`
      const res: any = await axios.get(temp_url)
      data = res.data
    }

    for (let user of data.users) {
      users.push(user)
    }

    if (data.index) {
      lastIndex = data.index
    } else {
      paginate = false
    }
  }

  return { data: users }
}

export const getAllCognitoUsers = async () => {
  const users: any[] = []
  const url = PIGEON_API_URL + '/admin/cognito-users'
  let tempUrl = url
  let paginate = true

  while (paginate) {
    const { data } = await axios.get(tempUrl)

    if (data.paginationToken) {
      let token = btoa(data.paginationToken)
      tempUrl = url + `?next_page=${token}`
    } else {
      paginate = false
    }

    for (let user of data.users) {
      users.push(user)
    }
  }

  return users
}

export const getUserById = async (email: string) => {
  const url = `${PIGEON_API_URL}/admin/users/${email}`
  return axios.get(url)
}

export const patchUser = async (email, payload) => {
  const url = `${PIGEON_API_URL}/admin/users/${email}`
  return axios.patch(url, payload)
}

export const postPigeonPoints = async (email, payload) => {
  const url = `${PIGEON_API_URL}/admin/users/${email}/pigeon-points`
  return axios.post(url, payload)
}
