import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import classes from './Sections.module.scss'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal'
import TextField from '@mui/material/TextField'
import { LoadingButton } from '@mui/lab'
import { postPigeonPoints } from '../../../services/users.service'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from '../../../redux/store'
import { fetchUserById } from '../../../redux/slices/usersSlice'
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

export const PigeonPointsSection = ({ pigeonPoints, user }) => {
  const [showAddPP, setShowAddPP] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<any>({ points: '', reason: '', action: 'addition' })
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const handleGivePoints = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await postPigeonPoints(user.AccountId, { ...form })
      await dispatch(fetchUserById(user.AccountId)).unwrap()
      setShowAddPP(false)
      enqueueSnackbar('Success!', { variant: 'success' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Failed to update', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SimpleSectionCard
        header={`Pigeon Points  (${user?.PigeonPoints})`}
        detailsClassName={'pt-2 gap-1 flex flex-col'}
        containerClassName={'h-max'}
        action={
          <Button onClick={() => setShowAddPP(true)}>
            <Typography className={'text-green-600'}>Add</Typography>/
            <Typography className={'text-red-600'}>Subtract</Typography>
          </Button>
        }
      >
        {!pigeonPoints || !pigeonPoints.length ? 'User has no pigeon points history' : null}

        {[...pigeonPoints]
          .sort((a, b) => b.dateAwarded - a.dateAwarded)
          .map((paperTrail, index) => {
            return (
              <div className={'flex flex-row gap-3 hover:bg-gray-200'} key={index}>
                <span className={classes.sectionField}>
                  {new Date(paperTrail.dateAwarded * 1000).toLocaleDateString()}
                </span>
                <span className={classes.sectionFieldLarge}>
                  for: <b>{paperTrail.eventType}</b>
                </span>
                <span
                  className={` ${classes.sectionField} ${
                    paperTrail.paperTrailAction === 'addition'
                      ? 'text-green-600'
                      : paperTrail.eventType === 'manual'
                      ? 'text-red-600'
                      : 'text-blue-600'
                  }`}
                >
                  {paperTrail.paperTrailAction === 'addition'
                    ? 'awarded'
                    : paperTrail.eventType === 'manual'
                    ? 'removed'
                    : 'redeemed'}
                </span>
                <span>{paperTrail.pointsAmount}</span>
              </div>
            )
          })}
      </SimpleSectionCard>

      <SimpleModal
        open={showAddPP}
        title={'Give Points'}
        bodyText={
          <form className={'flex flex-col gap-4 py-4 min-w-[300px]'} onSubmit={handleGivePoints}>
            <TextField
              required={true}
              type={'number'}
              label={'Points'}
              name={'points'}
              inputProps={{ min: 0, step: 1 }}
              onChange={(e) => setForm({ ...form, points: e.target.value })}
            />
            <TextField
              required={true}
              type={'text'}
              label={'Reason'}
              name={'reason'}
              onChange={(e) => setForm({ ...form, reason: e.target.value })}
            />

            <ToggleButtonGroup
              color={form.action === 'addition' ? 'success' : 'error'}
              value={form.action}
              exclusive
              onChange={(e, value) => setForm({ ...form, action: value })}
            >
              <ToggleButton value="addition">Add</ToggleButton>
              <ToggleButton value="subtraction">Subtract</ToggleButton>
            </ToggleButtonGroup>

            <LoadingButton variant={'contained'} color={'success'} loading={loading} type={'submit'}>
              Submit
            </LoadingButton>
          </form>
        }
        handleClose={() => setShowAddPP(false)}
      />
    </>
  )
}
