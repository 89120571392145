import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { patchLoan } from '../../../../services/loans.service'
import { TPigeonContract } from '../../../../typescript/TPigeonContract'
import { useAppDispatch } from '../../../../redux/store'
import { fetchContractById } from '../../../../redux/slices/contractsSlice'
import { useSnackbar } from 'notistack'

type Props = {
  open: boolean
  handleClose: () => void
  contract: TPigeonContract
}

export const UpdatePaymentDateModal = ({ open, handleClose, contract }: Props) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  let today = new Date()
  today.setDate(today.getDate() + 2)

  const updateNextPaymentDate = async () => {
    if (!date) {
      return
    }

    try {
      setLoading(true)
      await patchLoan(contract.ContractId, {
        updateNextPaymentDate: new Date(date).getTime(),
      })
      await dispatch(fetchContractById(contract.ContractId)).unwrap()
      enqueueSnackbar('Updated!', { variant: 'success' })
      handleClose()
    } catch (e) {
      console.error(e)
      enqueueSnackbar('Error', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!open) {
      setDate('')
    }
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Next Payment Date</DialogTitle>
      <DialogContent>
        <p>Please select a new payment date.</p>
        <input
          style={{ display: 'block', margin: '0 auto' }}
          type={'date'}
          value={date}
          onChange={(ev) => setDate(ev.target.value)}
          min={today.toISOString().split('T')[0]}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant={'contained'}
          color={'success'}
          onClick={updateNextPaymentDate}
          disabled={!date}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
