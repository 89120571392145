import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'

export const BankSection = ({ title, subheader = '', banks }) => {
  return (
    <SimpleSectionCard
      header={title}
      subHeader={subheader}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {!banks || !banks.length ? 'User has no banks' : null}

      {banks?.map((bank, i) => {
        return (
          <div className={'flex flex-row gap-3'} key={i}>
            <span className={'textLink'} onClick={() => navigator.clipboard.writeText(bank.id)}>
              ID
            </span>
            <span>{bank.nickname}</span>
            <span>x{bank.trailingDigits}</span>
            <span className={bank.verified ? 'text-green-600' : 'text-orange-400'}>
              {bank.verified ? 'verified' : 'not verified'}
            </span>
            <span>{bank.id.includes('pm_') ? 'instant' : 'manual'}</span>
            <span>{bank.created ? new Date(bank.created * 1000).toLocaleDateString() : 'n/a'}</span>
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
