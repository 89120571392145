import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import { useEffect } from 'react'
import classes from './Sections.module.scss'
import { Chip } from '@mui/material'
import { formatMoney, getStripeRedirect } from '../../../utils/utils'

export const PaymentsSection = ({ title, subheader = '', contract }) => {
  const payments = getPayments()

  function getPayments() {
    let temp: any[] = []
    const idsSet = new Set()

    contract.StripePaymentsQueue.forEach((payment) => {
      idsSet.add(payment.id)
      temp.push({
        id: payment.id,
        amount: payment.totalTransaction,
        sentOn: payment.date,
        type: payment.stage,
        source: payment.type,
        recipient: payment.recipient,
        isPending: true,
        senderEmail: payment.sender,
      })
    })

    contract.FreebirdQueue.forEach((payment) => {
      idsSet.add(payment.id)
      temp.push({
        id: payment.id,
        amount: payment.amount,
        sentOn: payment.date,
        type: payment.stage,
        source: `freebird - ${payment.medium}`,
        recipient: payment.recipient,
        isPending: true,
        senderEmail: payment.sender,
      })
    })

    contract?.Payments?.forEach((payment) => {
      if (!idsSet.has(payment.Identifier)) {
        temp.push({
          id: payment.Identifier,
          amount: payment.Amount,
          sentOn: payment.Date,
          type: payment.Stage,
          source: payment.Type === 'freebird' ? `${payment.Type} - ${payment.Medium}` : payment.Type,
          recipient: payment.Recipient,
          isPending: false,
          senderEmail: payment.Sender,
        })
      }
    })

    temp.sort((a, b) => new Date(b.sentOn).getTime() - new Date(a.sentOn).getTime())

    return temp
  }

  useEffect(() => {}, [contract])

  return (
    <SimpleSectionCard
      header={title}
      subHeader={subheader}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {!payments.length ? 'Contract has no payments' : null}

      {payments?.map((payment, i) => {
        return (
          <div className={'flex flex-row gap-3 hover:bg-gray-200'} key={i}>
            <span className={'textLink'} onClick={() => navigator.clipboard.writeText(payment.id)}>
              ID
            </span>
            <span className={`${classes.sectionField}`}>{formatMoney(payment.amount)}</span>
            <span className={`${classes.sectionFieldLarge}`}>
              <i>recip.</i> {payment.recipient}
            </span>
            <span
              className={`${classes.sectionFieldLarge} ${!payment.source.includes('freebird') && 'exteriorLink'}`}
              onClick={() => (!payment.source.includes('freebird') ? getStripeRedirect(payment) : null)}
            >
              {payment.source}
            </span>
            <span className={`${classes.sectionField}`}>{payment.type}</span>
            <span className={`${classes.sectionField}`}>{new Date(payment.sentOn * 1000).toLocaleDateString()}</span>
            <span>{payment.isPending ? <Chip label="pending" size="small" color={'warning'} /> : ''}</span>
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
