import React from 'react'
import DEAD_PIGEON from '../assets/images/pigeon-dead.svg'
import { useRouteError } from 'react-router-dom'

function NotFound() {
  const error = useRouteError()
  console.error(error)

  return (
    <div className={'container-fluid text-center pigeon-text-color shift-parent-down'}>
      <div className="row align-items-center justify-content-center" style={{ minHeight: '80vh' }}>
        <div className="col-auto">
          <img src={DEAD_PIGEON} alt={'Pigeon Not Found'} style={{ width: '40vw', height: '40vh' }} />
          <h2 className="pigeon-text-color mt-1 mb-3">Page Not Found</h2>
          <button type={'button'} className="button button-primary" onClick={() => ({})}>
            Return Home
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFound
