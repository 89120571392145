import { SimpleSectionCard } from '../../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../../../components/LabelAndValue/LabelAndValue'
import { formatMoney } from '../../../../utils/utils'
import { STRIPE_BASE_URL } from '../../../../constants'

export const TierDetailsSection = ({ tierVersion, sectionTitle }) => {
  const getStatus = () => {
    if (tierVersion.Active) {
      return <b className={'text-green-600'}>Active</b>
    } else if (tierVersion.ArchivedAt) {
      return <b className={'text-red-600'}>Archived</b>
    } else {
      return <b className={'text-gray-600'}>Draft</b>
    }
  }

  return (
    <SimpleSectionCard header={sectionTitle}>
      <LabelAndValue label={'Name'} value={tierVersion.Name} />
      <LabelAndValue label={'Tier Id'} value={tierVersion.TierId} />
      <LabelAndValue label={'Tier Version Id'} value={tierVersion.Id} />
      <LabelAndValue label={'Status'} value={getStatus()} />
      <LabelAndValue label={'Monthly Payment'} value={formatMoney(tierVersion.MonthlyAmount)} />
      <LabelAndValue label={'Full Payment Discount'} value={tierVersion.FullPaymentDiscountPercentage + '%'} />
      <LabelAndValue label={'Version'} value={`${tierVersion.Version}`} />

      <LabelAndValue label={'Created By'} value={tierVersion.CreatedBy} />
      <LabelAndValue label={'Created Date'} value={new Date(tierVersion.CreatedAt * 1000).toDateString()} />
      {tierVersion.ArchivedBy && <LabelAndValue label={'Archived By'} value={tierVersion.ArchivedBy} />}
      {tierVersion.ArchivedAt && (
        <LabelAndValue label={'Archived Date'} value={new Date(tierVersion.ArchivedAt * 1000).toDateString()} />
      )}
      {tierVersion?.StripeProductId && (
        <LabelAndValue
          label={'Stripe Product Id'}
          value={
            <a href={`${STRIPE_BASE_URL}/products/${tierVersion.StripeProductId}`} rel="noreferrer" target={'_blank'}>
              {tierVersion.StripeProductId}
            </a>
          }
        />
      )}
      <LabelAndValue label={'Description'} value={tierVersion.Description || 'N/A'} />
    </SimpleSectionCard>
  )
}
