import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  initialDataLoaded: false,
  lastDataRefreshDate: parseInt(window.localStorage.getItem('lastRefresh') || '0'),
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLastDataRefresh: (state, action) => {
      state.lastDataRefreshDate = new Date().getTime()
      window.localStorage.setItem('lastRefresh', state.lastDataRefreshDate.toString())
    },
    setInitialDataDownload: (state, action) => {
      state.initialDataLoaded = true
    },
  },
})

// eslint-disable-next-line no-empty-pattern
export const { setLastDataRefresh, setInitialDataDownload } = appSlice.actions

export default appSlice.reducer

// Below are selectors

export const selectDidInitiallyDownload = (state) => state.appSlice.initialDataLoaded

export const selectLastDataRefreshDate = (state) => state.appSlice.lastDataRefreshDate
