import { TPigeonContract } from '../../../../typescript/TPigeonContract'
import { useState } from 'react'
import { UpdatePaymentDateModal } from './UpdatePaymentDateModal'
import Button from '@mui/material/Button'
import { UpdateContractTierModal } from './UpdateContractTierModal'
import { LoadingButton } from '@mui/lab'
import { fetchContractById } from '../../../../redux/slices/contractsSlice'
import { useAppDispatch } from '../../../../redux/store'
import { useSnackbar } from 'notistack'
import { patchLoan } from '../../../../services/loans.service'

type Props = {
  contract: TPigeonContract
}

export const LoanActions = ({ contract }: Props) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [showUpdatePaymentDateModal, setShowUpdatePaymentDateModal] = useState(false)
  const [showChangeContractTierModal, setShowChangeContractTierModal] = useState(false)
  const [loadingRemovePayer, setLoadingRemovePayer] = useState(false)

  const removePremiumTierPayer = async () => {
    try {
      setLoadingRemovePayer(true)
      await patchLoan(contract.ContractId, { removePremiumUpgradePayer: true })
      await dispatch(fetchContractById(contract.ContractId)).unwrap()
      enqueueSnackbar('Removed!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Failed to remove!', { variant: 'error' })
    } finally {
      setLoadingRemovePayer(false)
    }
  }

  return (
    <>
      <div className={'flex flex-wrap gap-6'}>
        <div className={'flex-col text-center'}>
          <h5 className={'mb-1'}>Change Next Payment Date</h5>
          <Button
            size={'small'}
            variant={'contained'}
            color={'secondary'}
            onClick={() => setShowUpdatePaymentDateModal(true)}
            className={'mx-2'}
            disabled={contract.ContractStatus !== 'Active'}
          >
            Update
          </Button>
        </div>
        {contract.ContractStatus === 'Pending' && (
          <div className={'flex-col text-center'}>
            <h5 className={'mb-1'}>Change Tier</h5>
            <Button
              size={'small'}
              variant={'contained'}
              color={'secondary'}
              onClick={() => setShowChangeContractTierModal(true)}
              className={'mx-2'}
            >
              Change
            </Button>
          </div>
        )}

        {contract.ContractStatus === 'Pending' && contract.TierDetails?.PaidBy ? (
          <div className={'flex-col text-center'}>
            <h5 className={'mb-1'}>Remove Premium Payer</h5>
            <LoadingButton
              size={'small'}
              variant={'contained'}
              color={'secondary'}
              onClick={removePremiumTierPayer}
              className={'mx-2'}
              loading={loadingRemovePayer}
            >
              Remove
            </LoadingButton>
          </div>
        ) : null}
      </div>
      <UpdatePaymentDateModal
        contract={contract}
        open={showUpdatePaymentDateModal}
        handleClose={() => setShowUpdatePaymentDateModal(false)}
      />
      <UpdateContractTierModal
        contract={contract}
        open={showChangeContractTierModal}
        handleClose={() => setShowChangeContractTierModal(false)}
      />
    </>
  )
}
