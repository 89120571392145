import { TierForm } from './TierForm'

type Props = {
  tierVersions?: any
  onCancelEditMode: () => void
}

export const EditModeView = ({ tierVersions, onCancelEditMode }: Props) => {
  return (
    <div className={'flex flex-row gap-4 w-full'}>
      <TierForm tierVersion={tierVersions?.length ? tierVersions[0] : null} onCancelEditMode={onCancelEditMode} />

      <div>
        <h3>Creating/Updating a Tier</h3>
        <p>
          When first creating a tier, the tier will be in "draft" mode. You may update a draft tier as much as you want.
        </p>
        <br />
        <p>
          Once a Tier is active, you must create a new version of a tier if you wish to edit the tier. The new tier
          version will be in draft mode, and you may edit it as much as you want until you activate it.
        </p>
        <br />
        <p>
          When a tier version is activated, the previous tier version is archived and all new subscriptions for this
          tier will have the new activated version.
        </p>
        <br />
        <p>All contracts with the archived version of the tier, will maintain having that tier.</p>
      </div>
    </div>
  )
}
