import { Box, Fab } from '@mui/material'
import { Layout } from '../../components/Layout/Layout'
import React, { useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { fetchAllTiers, selectAllTiersArray } from '../../redux/slices/tiersSlice'
import { useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { TierDisplay } from './components/TierDisplay'

export const SubscriptionTiersPage = () => {
  const tiers: any[] = useSelector(selectAllTiersArray)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchAllTiers())

    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <div className={'w-full h-fit flex flex-col py-4 px-12 box-border '}>
        <div className={'flex justify-between items-center'}>
          <h1 className={'text-5xl'}>Subscription Tiers</h1>
          <Fab color={'success'} onClick={() => navigate('/subscription-tiers/new')}>
            <AddIcon />
          </Fab>
        </div>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          {tiers.map((tier) => (
            <TierDisplay tier={tier} key={tier.id} />
          ))}
        </Box>
      </div>
    </Layout>
  )
}
