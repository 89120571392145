import { Card } from '@mui/material'
import classes from './LoginPage.module.scss'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { NewPasswordForm } from './NewPasswordForm'
import { LoadingButton } from '@mui/lab'

export const LoginPage = () => {
  const [form, setForm] = useState({ email: '', password: '' })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [newPasswordState, setNewPasswordState] = useState(false)
  const [cognitoUser, setCognitoUser] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const validEmailDomains = ['usepigeon.io', 'pigeonloans.io']

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (!validEmailDomains.find((domain) => form.email.includes(domain))) {
        enqueueSnackbar('Unable to login, not a Pigeon email', {
          variant: 'error',
        })
        return
      }

      const user = await Auth.signIn(form.email, form.password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(user)
        setNewPasswordState(true)
      } else {
        handleAfterLogin(user)
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Failed to login', {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAfterLogin = (user) => {
    if (user) {
      let path = location?.state?.pathname || '/'
      navigate(path)
    }

    setLoading(false)
  }

  useEffect(() => {
    const isLoggedIn = async () => {
      const user = await Auth.currentUserInfo()
      if (user) {
        setLoading(true)
        handleAfterLogin(user)
      }
    }
    isLoggedIn().then()

    // eslint-disable-next-line
  }, [])

  return (
    <div className={'w-screen h-screen flex flex-col justify-center items-center'}>
      <h1 className={'absolute top-8'}>Pigeon Loans - Admin</h1>

      <Card className={classes.container}>
        {!newPasswordState && (
          <form onSubmit={handleLogin} className={'flex flex-col gap-4'}>
            <h2 className={'text-center text-3xl m-0'}>Login</h2>
            <TextField
              label="Email"
              variant="standard"
              name={'email'}
              type={'email'}
              onChange={handleChange}
              required={true}
            />

            <TextField
              label="Password"
              variant="standard"
              name={'password'}
              type={'password'}
              onChange={handleChange}
              required={true}
            />

            <LoadingButton type={'submit'} variant={'contained'} loading={loading}>
              Login
            </LoadingButton>
          </form>
        )}

        {newPasswordState && <NewPasswordForm email={form.email} cognitoUser={cognitoUser} />}
      </Card>
    </div>
  )
}
