import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/LoginPage/LoginPage'
import { PrivateRoute } from './components/PrivateRoute'
import { DashboardPage } from './pages/DashboardPage/DashboardPage'
import UnauthorizedPage from './pages/UnauthorizedPage'
import NotFound from './pages/NotFoundPage'
import { UsersPage } from './pages/UsersPage/UsersPage'
import { ContractsPage } from './pages/ContractsPage/ContractsPage'
import { UserDetailPage } from './pages/UserDetailPage/UserDetailPage'
import { ContractDetailsPage } from './pages/ContractDetailsPage/ContractDetailsPage'
import { SubscriptionTiersPage } from './pages/SubscriptionTiersPage/SubscriptionTiersPage'
import { SubscriptionTierDetailsPage } from './pages/SubscriptionTierDetailsPage/SubscriptionTierDetailsPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<DashboardPage />} />
        </Route>

        <Route path="/users" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<UsersPage />} />
        </Route>

        <Route path="/contracts" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<ContractsPage />} />
        </Route>

        <Route path="/subscription-tiers" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<SubscriptionTiersPage />} />
        </Route>

        <Route path="/subscription-tiers/:id" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<SubscriptionTierDetailsPage />} />
        </Route>

        <Route path="/users/:id" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<UserDetailPage />} />
        </Route>

        <Route path="/contracts/:id" element={<PrivateRoute />} errorElement={<NotFound />}>
          <Route path="" element={<ContractDetailsPage />} />
        </Route>

        {/* PUBLIC ROUTES BELOW */}

        <Route path="/login" element={<LoginPage />} errorElement={<NotFound />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} errorElement={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
