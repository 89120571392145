import { SimpleSectionCard } from '../../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../../../components/LabelAndValue/LabelAndValue'
import { formatMoney } from '../../../../utils/utils'

export const LoanConstraintsSection = ({ tierVersion, sectionTitle }) => {
  return (
    <SimpleSectionCard header={sectionTitle}>
      <LabelAndValue
        label={'Max Principle'}
        value={tierVersion.MaxLoanPrincipal ? formatMoney(tierVersion.MaxLoanPrincipal) : 'No Limit'}
      />

      <LabelAndValue label={'Max Duration'} value={tierVersion.MaxDurationInMonths} />
    </SimpleSectionCard>
  )
}
