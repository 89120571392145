import { LoadingButton } from '@mui/lab'
import { patchUser } from '../../../services/users.service'
import { fetchUserById } from '../../../redux/slices/usersSlice'
import { useAppDispatch } from '../../../redux/store'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

export const UserActions = ({ user }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [activationLoading, setActivationLoading] = useState(false)
  const [resetStripeLoading, setResetStripeLoading] = useState(false)

  const toggleActivation = async () => {
    setActivationLoading(true)
    try {
      await patchUser(user.email, { toggleActivation: true })
      await dispatch(fetchUserById(user.email)).unwrap()
      enqueueSnackbar('Successfully updated users activation', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar('Failed to update users activation', {
        variant: 'error',
      })
    } finally {
      setActivationLoading(false)
    }
  }
  const resetStripeConnectedAccount = async () => {
    setResetStripeLoading(true)
    try {
      await patchUser(user.email, { resetConnectedAccount: true })
      await dispatch(fetchUserById(user.email)).unwrap()
      enqueueSnackbar('Successfully reset stripe connected account', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar('Failed to reset', {
        variant: 'error',
      })
    } finally {
      setResetStripeLoading(false)
    }
  }

  return (
    <div className={'p-4 gap-4 flex flex-row flex-wrap'}>
      <div className={'flex-col text-center'}>
        <h5 className={'mb-1'}>Toggle cognito activation</h5>
        <LoadingButton
          size={'small'}
          variant={'contained'}
          color={'secondary'}
          onClick={toggleActivation}
          className={'mx-2'}
          loading={activationLoading}
        >
          {user?.Enabled ? 'Disable' : 'Enable'} user
        </LoadingButton>
      </div>

      <div className={'flex-col text-center'}>
        <h5 className={'mb-1'}>Reset Stripe Connected Account</h5>
        <LoadingButton
          size={'small'}
          variant={'contained'}
          color={'secondary'}
          onClick={resetStripeConnectedAccount}
          className={'mx-2'}
          loading={resetStripeLoading}
          disabled={!user.StripeId || user.AccountActive}
        >
          reset
        </LoadingButton>
      </div>
    </div>
  )
}
