import { useSelector } from 'react-redux'
import { selectAllUsers } from '../../redux/slices/usersSlice'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { Layout } from '../../components/Layout/Layout'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ONE_MIN_IN_MS } from '../../constants'

export const UsersPage = () => {
  const rows = useSelector(selectAllUsers)
  const [pageSize, setPageSize] = useState(50)
  let columns: GridColDef[] = [
    {
      field: 'given_name',
      headerName: 'First name',
      width: 150,
    },
    {
      field: 'family_name',
      headerName: 'Last name',
      width: 150,
    },
    {
      field: 'AccountActive',
      headerName: 'Payments Enabled',
      description: 'This field represents in-app money being enabled. (Activated with stripe).',
      sortable: true,
      width: 160,
    },
    {
      field: 'Enabled',
      headerName: 'Auth Enabled (Cognito)',
      description: 'This field shows if users Cognito account is enabled.',
      sortable: true,
      width: 160,
    },
    {
      field: 'email_verified',
      headerName: 'Email Confirmed',
      description: 'This field shows if user email is confirmed. (Cognito)',
      sortable: true,
      width: 160,
    },
    {
      field: 'ActiveContracts',
      headerName: 'Active contracts',
      description: 'How many active contracts',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.row.ActiveContracts?.length || 0,
    },
    {
      field: 'InactiveContracts',
      headerName: 'Inactive contracts',
      description: 'How many inactive contracts',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.row.InactiveContracts?.length || 0,
    },
    {
      field: 'PendingContracts',
      headerName: 'Pending contracts',
      description: 'How many pending contracts',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.row.PendingContracts?.length || 0,
    },
    {
      field: 'UnpaidInvoices',
      headerName: 'Has Unpaid Invoices',
      description: 'Says if this user has unpaid invoices',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.ContractSubscriptionsDetails?.UnpaidInvoices ? 'Yes' : 'No',
    },
    {
      field: 'PaymentMethodFailure',
      headerName: 'Failed Payment Method',
      description: 'Says if this user has a failed payment method for subscription',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.ContractSubscriptionsDetails?.PaymentMethodFailed ? 'Yes' : 'No',
    },
    {
      field: 'hearAboutUs',
      headerName: 'Heard About Us',
      description: 'How the user heard about us',
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => params.row.Metadata?.attribute_values?.hearAboutUs || null,
    },
    {
      field: 'PigeonPoints',
      headerName: 'Pigeon Points',
      description: 'Users Pigeon Points',
      sortable: true,
      width: 160,
    },
    {
      field: 'StripeCustomerId',
      headerName: 'Connected Account Id (Stripe)',
      description: 'This is their stripe connect account id, enables them to receive payments',
      sortable: true,
      width: 160,
      hide: true,
    },
    {
      field: 'StripeId',
      headerName: 'Customer Id (Stripe)',
      description: 'This is their stripe customer id, enables them to make payments',
      sortable: true,
      width: 160,
      hide: true,
    },
    {
      field: 'LastLogin',
      headerName: 'Last Login',
      description: 'This is the last time they logged into pigeon loans.',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value && params.value !== -1 ? new Date(params.value * 1000).toDateString() : '',
    },
    {
      field: 'UserCreateDate',
      headerName: 'Created Date',
      description: 'Date their account was created',
      sortable: true,
      width: 160,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value ? new Date(params.value).toDateString() : '',
    },
    {
      field: 'custom:birthYear',
      headerName: 'Birth Year',
      description: 'Self reported birth year',
      sortable: true,
      width: 160,
    },
    {
      field: 'custom:personalIncome',
      headerName: 'Income Range',
      description: 'Self reported income range',
      sortable: true,
      width: 160,
    },
    {
      field: 'custom:gender',
      headerName: 'Gender',
      description: 'Self reported gender',
      sortable: true,
      width: 160,
    },
    {
      field: 'custom:ethnicity',
      headerName: 'Ethnicity',
      description: 'Self reported ethnicity',
      sortable: true,
      width: 160,
    },
    {
      field: 'ReturningActiveUser',
      headerName: 'Returning Active User',
      description:
        'Returning Active User: Yes if a user has logged in 30 days after creating an account AND has logged in the last 30 days. Maybe if they havent been around for 30 days yet.',
      sortable: true,
      width: 250,
      valueGetter: (params: GridValueGetterParams) => {
        const lastLogin = params.row.LastLogin

        if (!lastLogin) {
          return 'No'
        }

        const ONE_DAY_IN_MS = ONE_MIN_IN_MS * 1440
        const MONTH_IN_MS = ONE_MIN_IN_MS * 60 * 24 * 30
        const loggedInLastMonth = new Date().getTime() - new Date(lastLogin * 1000).getTime() < MONTH_IN_MS

        if (!loggedInLastMonth) {
          return 'No'
        }

        const createdAndLastLoginDiff =
          new Date(lastLogin * 1000).getTime() - new Date(params.row.UserCreateDate).getTime() >= 30 * ONE_DAY_IN_MS

        if (createdAndLastLoginDiff) {
          return 'Yes'
        }

        return 'Maybe'
      },
    },
  ]

  columns.sort((a: any, b: any) => (a?.headerName < b?.headerName ? -1 : 1))

  columns = [
    {
      field: 'id',
      headerName: 'Email',
      width: 180,
      description: 'This field id clickable if the user has both cognito data and pigeon loans data.',
      valueGetter: (params: GridValueGetterParams) => params.row.AccountId || params.row.email,
      renderCell: (params: GridRenderCellParams<string>) =>
        params.row.AccountId && params.row.email ? (
          <Link className={'textLink'} to={`/users/${params.value}`}>
            {params.value}
          </Link>
        ) : (
          params.value
        ),
    },
    ...columns,
  ]

  return (
    <Layout>
      <div className={'w-full h-fit flex flex-col py-4 px-12 box-border '}>
        <div className={'flex justify-between items-center'}>
          <h1 className={'text-5xl'}>Users</h1>
        </div>

        <Box className={'m-auto'} sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(size) => setPageSize(size)}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
            disableSelectionOnClick
            getRowId={(row) => row.AccountId || row.email}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </div>
    </Layout>
  )
}
