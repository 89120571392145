import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'

export const CardsSection = ({ title, subheader = '', cards }) => {
  return (
    <SimpleSectionCard
      header={title}
      subHeader={subheader}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {!cards || !cards.length ? 'User has no cards' : null}

      {cards?.map((card, i) => {
        return (
          <div className={'flex flex-row gap-3'} key={i}>
            <span className={'textLink'} onClick={() => navigator.clipboard.writeText(card.id)}>
              ID
            </span>
            <span>
              {card.brand} x{card.last4}
            </span>
            <span>
              exp. {card.exp_month}/{card.exp_year}
            </span>
            <span>{card.created ? new Date(card.created * 1000).toLocaleDateString() : ''}</span>
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
