import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import { formatMoney } from '../../../utils/utils'
import classes from './Sections.module.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const LoansSection = ({ title, subheader = '', loans = [], userEmail }) => {
  const navigate = useNavigate()
  const [sortedLoans, setSortedLoans] = useState([])

  useEffect(() => {
    const status = { Pending: [], Active: [], Inactive: [] }

    loans.forEach((loan: any) => status[loan.ContractStatus].push(loan))

    setSortedLoans([...status.Active, ...status.Pending, ...status.Inactive])
  }, [loans])

  return (
    <SimpleSectionCard
      header={title}
      subHeader={subheader}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {!loans || !loans.length ? 'User has no loans' : null}

      {sortedLoans?.map((loan: any, i) => {
        const isBorrowing = loan.Borrower.toLowerCase() === userEmail
        const counterpartyEmail = isBorrowing ? loan.Lender : loan.Borrower

        return (
          <div className={'flex flex-row gap-3 hover:bg-gray-200'} key={i}>
            <span
              className={`${classes.sectionFieldLarge} textLink`}
              onClick={() => navigate(`/contracts/${loan.ContractId}`)}
            >
              {loan.ContractId}
            </span>
            <span className={classes.sectionField}>{loan.ContractStatus}</span>
            <span className={classes.sectionField}>{formatMoney(loan.Principal)}</span>
            <span className={classes.sectionField}>{isBorrowing ? 'Borrowing' : 'Lending'}</span>
            <span onClick={() => navigate(`/users/${counterpartyEmail}`)} className={'textLink'}>
              {counterpartyEmail}
            </span>
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
