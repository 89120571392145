import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import React from 'react'

type Props = {
  open: boolean
  title: string
  bodyText: string | React.ReactNode
  handleClose: () => void
  onCancel?: () => void
  onSuccess?: () => void
  successButtonText?: string
  cancelButtonText?: string
}

export const SimpleModal = ({
  open,
  handleClose,
  onCancel,
  onSuccess,
  title,
  bodyText,
  successButtonText = 'Submit',
  cancelButtonText = 'Cancel',
}: Props) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof bodyText === 'string' ? <DialogContentText>{bodyText}</DialogContentText> : bodyText}
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button variant={'outlined'} onClick={onCancel}>
            {cancelButtonText}
          </Button>
        )}
        {onSuccess && (
          <Button variant={'contained'} color={'success'} onClick={onSuccess}>
            {successButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
