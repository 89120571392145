import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { CachedOutlined, Dashboard, Person, ReceiptLong, Search, Subscriptions } from '@mui/icons-material'
import { useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { fetchAllCognitoUsers, fetchAllUsers, selectIsAllUsersLoading } from '../../redux/slices/usersSlice'
import { fetchAllContracts, selectIsAllContractsLoading } from '../../redux/slices/contractsSlice'
import { selectLastDataRefreshDate, setLastDataRefresh } from '../../redux/slices/appSlice'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import { Auth } from 'aws-amplify'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { fetchAllTiers } from '../../redux/slices/tiersSlice'

const drawerWidth = 240

export const Layout = ({ localLoading = false, children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const loadingUsers = useSelector(selectIsAllUsersLoading)
  const loadigContracts = useSelector(selectIsAllContractsLoading)
  const loading = loadingUsers || loadigContracts
  const lastDataRefresh = useSelector(selectLastDataRefreshDate)
  const [searchTerm, setSearchTerm] = useState('')

  const logout = async () => {
    window.localStorage.clear()
    await Auth.signOut()
    navigate('/login')
  }

  const refreshData = async () => {
    if (!loading) {
      await Promise.all([
        dispatch(fetchAllContracts()).unwrap(),
        dispatch(fetchAllUsers()).unwrap(),
        dispatch(fetchAllCognitoUsers()).unwrap(),
        dispatch(fetchAllTiers()).unwrap(),
      ])
      dispatch(setLastDataRefresh(null))
    }
  }

  const navOptions = [
    { icon: Dashboard, title: 'Dashboard', link: '/' },
    { icon: Person, title: 'Users', link: '/users' },
    { icon: ReceiptLong, title: 'Loans', link: '/contracts' },
    { icon: Subscriptions, title: 'Tiers', link: '/subscription-tiers' },
  ]

  const handleSearch = (e) => {
    e.preventDefault()
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (searchTerm == parseInt(searchTerm)) {
      navigate(`/contracts/${searchTerm}`)
    } else {
      navigate(`/users/${searchTerm}`)
    }
  }

  return (
    <Box className={'flex'}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} className={'bg-white'}>
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            className={'text-gray-700 hover:text-gray-900 cursor-pointer'}
            onClick={() => navigate('/')}
          >
            Pigeon ADMIN
          </Typography>

          <span className={'flex-1'} />
          <form onSubmit={handleSearch} className={'flex flex-row items-center justify-center'}>
            <TextField
              className={'w-[400px]'}
              label="Search by Email, Cognito Id, or Contract Id"
              variant="filled"
              name={'term'}
              type={'string'}
              required={true}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type={'submit'} size={'small'} disabled={!searchTerm}>
              <Search />
            </IconButton>
          </form>

          <span className={'flex-1'} />
          {lastDataRefresh && (
            <Typography className={'text-gray-700 mr-2'}>
              Last refresh:{' '}
              <b>
                {new Date(lastDataRefresh).toLocaleDateString()}
                {' @ '}
                {new Date(lastDataRefresh).toLocaleTimeString()}
              </b>
            </Typography>
          )}
          <IconButton onClick={refreshData}>
            <CachedOutlined className={`${loading || localLoading ? 'animate-spin text-blue-600' : ''} text-4xl`} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: 'auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <List>
            {navOptions.map((option) => {
              const Icon = option.icon
              const isActive =
                option.link === '/' ? location.pathname === option.link : location.pathname.includes(option.link)
              return (
                <ListItem
                  key={option.title}
                  disablePadding
                  onClick={() => navigate(option.link)}
                  className={`${isActive ? 'bg-blue-300' : ''}`}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={option.title} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>

          <span className={'flex-1'} />

          <Button onClick={logout} variant={'contained'} className={'mb-4 mx-4'}>
            Logout
          </Button>
        </Box>
      </Drawer>

      <div className={'px-4 flex-1'}>
        <Toolbar />
        {children}
      </div>
    </Box>
  )
}
