import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../../components/LabelAndValue/LabelAndValue'
import { formatMoney } from '../../../utils/utils'

export const AutoPaySection = ({ subscription }) => {
  return (
    <SimpleSectionCard header={'Auto-pay'} detailsClassName={'pt-2 gap-1 flex flex-col'} containerClassName={'h-max'}>
      {!subscription?.subscriptionId && <p>Auto-pay not enabled</p>}
      {subscription?.subscriptionId && (
        <div>
          <LabelAndValue label={'Amount'} value={formatMoney(subscription?.subscriptionAmount)} />
          <LabelAndValue label={'Day of Month'} value={subscription?.subscriptionMonthDay} />
          <LabelAndValue label={'Frequency'} value={subscription?.frequency} />
          <LabelAndValue
            label={'Started'}
            value={subscription?.startTimestamp && new Date(subscription?.startTimestamp * 1000).toLocaleDateString()}
          />
          <LabelAndValue label={'Fee Amount'} value={formatMoney(subscription?.feeAmount)} />
        </div>
      )}
    </SimpleSectionCard>
  )
}
