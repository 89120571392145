import { useEffect } from 'react'
import { Layout } from '../../components/Layout/Layout'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../redux/store'
import { AsyncStates, InterestTypes } from '../../typescript/types'
import { SimpleSectionCard } from '../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../components/LabelAndValue/LabelAndValue'
import {
  fetchContractById,
  selectAllContractErrors,
  selectAllContracts,
  selectContractAsyncStatus,
} from '../../redux/slices/contractsSlice'
import { formatMoney, getLastOffer } from '../../utils/utils'
import { Alert } from '@mui/material'
import { PaymentsSection } from './Sections/PaymentsSection'
import { AutoPaySection } from './Sections/AutoPaySection'
import { CustomRepaymentsScheduleSection } from './Sections/CustomRepaymentsScheduleSection'
import { PremiumLoanDetailsSection } from './Sections/PremiumLoanDetailsSection'
import { CompoundDetailsSection } from './Sections/CompoundDetailsSection'
import { LoanActions } from './Sections/LoanActions/LoanActions'

export const ContractDetailsPage = () => {
  const allContracts = useSelector(selectAllContracts)
  const fetchingErrors = useSelector(selectAllContractErrors)
  const dispatch = useAppDispatch()
  const allContractsAsyncStates = useSelector(selectContractAsyncStatus)
  const params = useParams()
  const fetchingError = fetchingErrors[params.id!]
  const contract = allContracts.find((c: any) => c?.ContractId === parseInt(params.id!))

  const lastOffer = getLastOffer(contract)

  useEffect(() => {
    dispatch(fetchContractById(parseInt(params.id!)))
    //eslint-disable-next-line
  }, [params.id])

  useEffect(() => {
    // eslint-disable-next-line
  }, [])

  if (allContractsAsyncStates[params.id!] === AsyncStates.error) {
    return (
      <div className={'container mx-auto p-20'}>
        Failed to fetch contract with email: <b>{params.id!}</b>
        <br />
        <br />
        <Alert variant={'filled'} severity={'error'}>
          {fetchingError}
        </Alert>
        <Link className={'mx-auto block w-max mt-10 text-xl'} to={'/contracts'}>
          Back to Contracts
        </Link>
      </div>
    )
  }

  const getNextPaymentAmount = () => {
    if (!contract) {
      return 0
    }

    if (contract.CustomRepaymentSchedule?.length) {
      return formatMoney(
        contract?.CustomRepaymentSchedule?.[contract.InitialMonths - 1 - contract.RemainingMonths][0] || 0
      )
    } else {
      return contract.SuggestedMonthlyPayment < contract.PayoffAmount
        ? formatMoney(contract.SuggestedMonthlyPayment)
        : formatMoney(contract.PayoffAmount)
    }
  }

  if (!contract) {
    return <div>...Loading</div>
  }

  return (
    <Layout localLoading={allContractsAsyncStates[params.id!] === AsyncStates.pending}>
      <div className={'flex justify-between items-center px-8'}>
        <h1>Loan Details</h1>
      </div>

      <LoanActions contract={contract} />

      <div className={'p-4 gap-4 flex flex-row flex-wrap'}>
        <SimpleSectionCard
          header={'Basic Data'}
          detailsClassName={'pt-2 gap-1 flex flex-col height-max'}
          containerClassName={'h-max'}
        >
          <LabelAndValue label={'ID'} value={contract?.ContractId} />
          <LabelAndValue
            label={'Principal'}
            value={formatMoney(contract?.Principal === -1 ? lastOffer.Principal : contract?.Principal)}
          />
          <LabelAndValue
            label={'Total Amount'}
            value={formatMoney(contract?.TotalLoan === -1 ? lastOffer.TotalLoan : contract?.TotalLoan)}
          />
          <LabelAndValue
            label={'Interest Rate'}
            value={(contract?.InterestRate === -1 ? lastOffer?.InterestRate : contract?.InterestRate) + '%'}
          />
          <LabelAndValue
            label={'Interest Type'}
            value={
              (contract?.InterestType ? contract?.InterestType || 'Simple' : lastOffer?.InterestType || 'Simple') +
              ' Interest'
            }
          />
          <LabelAndValue
            label={'Duration'}
            value={(contract?.InitialMonths !== -1 ? contract?.InitialMonths : lastOffer?.Months) + ' months'}
          />
          <LabelAndValue
            label={'Created'}
            value={contract?.Offers?.length && new Date(contract.Offers[0].Date * 1000).toLocaleDateString()}
          />
          <LabelAndValue label={'Imported'} value={lastOffer?.Imported ? 'yes' : 'no'} />
          <LabelAndValue
            label={'Date Signed'}
            value={contract?.DateSigned && new Date(contract.DateSigned * 1000).toLocaleDateString()}
          />
          <LabelAndValue
            label={'Date Started'}
            value={
              contract?.StartDate &&
              contract?.StartDate !== '-1' &&
              new Date(contract.StartDate * 1000).toLocaleDateString()
            }
          />
          <LabelAndValue
            label={'Expected End Date'}
            value={contract?.ExpectedEndDate && new Date(contract?.ExpectedEndDate * 1000).toLocaleDateString()}
          />
          <LabelAndValue label={'Loan Reason'} value={contract?.LoanMetadata?.LoanReason} />
          <LabelAndValue label={'Contract Protection'} value={contract.ContractProtection ? 'yes' : 'no'} />
          <LabelAndValue label={'Custom Repayments'} value={contract.CustomRepaymentSchedule?.length ? 'yes' : 'no'} />
          <LabelAndValue label={'Description'} value={contract.Description} />
          {contract.ContractStatus === 'Inactive' && (
            <LabelAndValue label={'Closure Reason'} value={contract.ClosureReason} />
          )}
        </SimpleSectionCard>

        <SimpleSectionCard
          header={'Snapshot'}
          detailsClassName={'pt-2 gap-1 flex flex-col height-max'}
          containerClassName={'h-max'}
        >
          <LabelAndValue label={'Status'} value={contract.ContractStatus} />
          {contract.ContractStatus === 'Inactive' && (
            <LabelAndValue label={'Completed All Payments'} value={contract.PayoffAmount === 0 ? 'yes' : 'no'} />
          )}
          <LabelAndValue
            label={'Total Loan Remaining'}
            value={
              contract.ContractStatus === 'Inactive'
                ? '0'
                : formatMoney(contract.ContractStatus === 'Pending' ? lastOffer.Principal : contract.PayoffAmount)
            }
          />
          <LabelAndValue
            label={'Remaining Months'}
            value={
              (contract.ContractStatus === 'Inactive'
                ? '0'
                : contract.ContractStatus === 'Pending'
                ? lastOffer.Months
                : contract.RemainingMonths) + ' months'
            }
          />
          {contract.ContractStatus === 'Active' && (
            <LabelAndValue
              label={'Next Payment Date'}
              value={
                contract?.NextPaymentDate !== -1
                  ? new Date(contract?.NextPaymentDate * 1000).toLocaleDateString()
                  : 'n/a'
              }
            />
          )}
          {contract.ContractStatus === 'Active' && (
            <LabelAndValue label={'Next Payment Amount'} value={getNextPaymentAmount()} />
          )}
          <LabelAndValue
            label={'Loan Extended'}
            value={contract?.LoanExtension?.extension === 'accepted' ? 'yes' : 'no'}
          />
          <LabelAndValue label={'Payment Overdue'} value={contract?.LoanExtension?.overdue ? 'yes' : 'no'} />
          <LabelAndValue label={'Auto-pay'} value={contract?.SubscriptionDetails?.subscriptionId ? 'Enabled' : 'no'} />
        </SimpleSectionCard>

        <SimpleSectionCard
          header={'People'}
          detailsClassName={'pt-2 gap-1 flex flex-col height-max'}
          containerClassName={'h-max'}
        >
          <LabelAndValue label={'Borrower'} value={contract?.BorrowerName} />
          <LabelAndValue label={'Lender'} value={contract?.LenderName} />
          <LabelAndValue
            label={'Lender Email'}
            value={
              <Link className={'textLink'} to={`/users/${contract?.Lender}`}>
                {contract?.Lender}
              </Link>
            }
          />
          <LabelAndValue
            label={'Borrower Email'}
            value={
              <Link className={'textLink'} to={`/users/${contract?.Borrower}`}>
                {contract?.Borrower}
              </Link>
            }
          />
        </SimpleSectionCard>

        {contract.TierDetails?.PaymentStatus !== 'free' ? <PremiumLoanDetailsSection contract={contract} /> : null}

        <AutoPaySection subscription={contract?.SubscriptionDetails} />

        <PaymentsSection title={'Payments'} subheader={'Payments are all outgoing transactions'} contract={contract} />

        {contract.InterestType === InterestTypes.MONTHLY_COMPOUND && <CompoundDetailsSection contract={contract} />}

        {contract.CustomRepaymentSchedule?.length ? <CustomRepaymentsScheduleSection contract={contract} /> : null}
      </div>
    </Layout>
  )
}
