import { PIGEON_API_URL } from '../constants'
import axios from 'axios'

export const getAllSubscriptionTiers = async () => {
  const tiers: any[] = []

  let paginate = true
  const url = PIGEON_API_URL + `/admin/tiers?page_size=7000`
  let temp_url = url
  while (paginate) {
    const { data }: any = await axios.get(temp_url)

    for (let tier of data.tiers) {
      tiers.push(tier)
    }

    if (data.index) {
      temp_url = url + `&last_index=${data.index}`
    } else {
      paginate = false
    }
  }

  return { data: tiers }
}

export const getTierByTierId = async (id: number) => {
  const url = `${PIGEON_API_URL}/admin/tiers/${id}`
  return axios.get(url)
}

export const createTier = async (data) => {
  const url = `${PIGEON_API_URL}/admin/tiers/`
  const response = await axios.post(url, data)
  return response.data
}

export const createTierVersion = async (data, tierId) => {
  const url = `${PIGEON_API_URL}/admin/tiers/${tierId}/version`
  const response = await axios.post(url, data)
  return response.data
}

export const updatePutTierVersion = async (id, data) => {
  const url = `${PIGEON_API_URL}/admin/tiers/versions/${id}`
  const response = await axios.put(url, data)
  return response.data
}

export const updatePatchTier = async (id, data) => {
  const url = `${PIGEON_API_URL}/admin/tiers/versions/${id}`
  const response = await axios.patch(url, data)
  return response.data
}

export const deleteTierVersion = async (id) => {
  const url = `${PIGEON_API_URL}/admin/tiers/versions/${id}`
  const response = await axios.delete(url)
  return response.data
}
