import { Auth } from 'aws-amplify'
import axios from 'axios'

let Token: null | string = null
const message = 'User no longer authenticated'

export const getCognitoIdToken = async () => {
  const session = await Auth.currentSession()
  Token = session?.getIdToken()?.getJwtToken()
  let expiration = new Date(session.getIdToken().getExpiration() * 1000)

  if (expiration && expiration.getTime() < new Date().getTime()) {
    throw new axios.Cancel(message)
  }

  return {
    Token,
  }
}

export const isAuthenticated = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    if (cognitoUser) {
      return cognitoUser
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}
