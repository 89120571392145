import { ONE_MIN_IN_MS } from '../constants'

export const getMonthlyActiveUsersCount = (users = []) => {
  if (!Array.isArray(users)) {
    return 0
  }

  const MONTH_IN_MS = ONE_MIN_IN_MS * 60 * 24 * 30

  return users.reduce((acc, user: any) => {
    if (user.LastLogin && new Date().getTime() - new Date(user.LastLogin * 1000).getTime() < MONTH_IN_MS) {
      return acc + 1
    }
    return acc
  }, 0)
}

export const getMonthlyReturningActiveUsersCount = (users = []) => {
  if (!Array.isArray(users)) {
    return 0
  }

  const ONE_DAY_IN_MS = ONE_MIN_IN_MS * 1440

  const MONTH_IN_MS = ONE_MIN_IN_MS * 60 * 24 * 30

  return users.reduce((acc, user: any) => {
    const monthDiff = new Date().getTime() - new Date(user.LastLogin * 1000).getTime()

    const createdAndLastLoginDiff = user.LastLogin
      ? new Date(user.LastLogin * 1000).getTime() - new Date(user.UserCreateDate).getTime()
      : false

    if (user.LastLogin && monthDiff < MONTH_IN_MS && createdAndLastLoginDiff >= ONE_DAY_IN_MS * 30) {
      return acc + 1
    }
    return acc
  }, 0)
}

export const getLast24HoursActiveUsersCount = (users = []) => {
  if (!Array.isArray(users)) {
    return 0
  }

  const ONE_DAY = ONE_MIN_IN_MS * 60 * 24

  return users.reduce((acc, user: any) => {
    if (user.LastLogin && new Date().getTime() - new Date(user.LastLogin * 1000).getTime() < ONE_DAY) {
      return acc + 1
    }
    return acc
  }, 0)
}

export const getNumberActiveLoanBuckets = (users: any[]) => {
  if (!users.length) {
    return []
  }

  let buckets = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
  }

  users.forEach((user) => {
    const activeCount = user.ActiveContracts?.length || 0
    if (activeCount >= 6) {
      buckets[6] += 1
    } else {
      buckets[activeCount] += 1
    }
  })

  return Object.keys(buckets)
    .reverse()
    .map((key) => {
      return `${key === '6' ? '6+' : key} loans: ${buckets[key]}`
    })
}

export const getHeardAboutUsBuckets = (users: any[]) => {
  if (!users.length) {
    return []
  }

  let buckets: { [key: string]: number } = {}

  users
    .filter((u) => u.Metadata?.attribute_values?.hearAboutUs)
    .forEach((user) => {
      let key = user.Metadata.attribute_values.hearAboutUs!

      if (key.includes('other-')) {
        key = 'other'
      }

      buckets[key] = buckets[key] ? buckets[key] + 1 : 1
    })

  const totalCount = Object.values(buckets).reduce((acc, count) => acc + count, 0)

  return Object.keys(buckets)
    .sort((reasonA, reasonB) => {
      return buckets[reasonB] - buckets[reasonA]
    })
    .map((reason) => {
      return `${reason} - ${Math.round((buckets[reason] / totalCount) * 100)}% (${buckets[reason]})`
    }, [])
}

export const getDemographicBuckets = (users: any[], key) => {
  if (!users.length) {
    return []
  }

  let buckets: { [key: string]: number } = {}

  users.forEach((user) => {
    if (user[key]) {
      let value = user[key]
      if (buckets[value]) {
        buckets[value]++
      } else {
        buckets[value] = 1
      }
    }
  })

  const total = Object.values(buckets).reduce((acc, val) => acc + val, 0)

  return Object.keys(buckets)
    .map((key) => [key, buckets[key]])
    .sort((tupleA: any, tupleB: any) => tupleB[1] - tupleA[1])
    .map((tuple: any) => {
      return `${tuple[0]}: ${((tuple[1] / total) * 100).toFixed(0)}% (${tuple[1]})`
    })
}
