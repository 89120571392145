// When using TypeScript 4.x and above
// When using TypeScript 3.x and below
import '@mui/lab/themeAugmentation'
import { createTheme } from '@mui/material/styles'

const rootElement = document.getElementById('root')

export const theme = createTheme({
  components: {
    MuiTimeline: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'whitesmoke',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
})
