import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'

export const PayoutSection = ({ title, subheader = '', payouts }) => {
  return (
    <SimpleSectionCard
      header={title}
      subHeader={subheader}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {!payouts || !payouts.length ? 'User has no banks' : null}

      {payouts?.map((payout, i) => {
        const data: any = {
          id: payout.id,
          last4: `${payout.brand || ''} x${payout.last4}`.trim(),
          name: payout.bank_name,
          type: payout.bank_name ? 'Bank' : 'Debit Card',
          exp: payout.exp_month ? `${payout.exp_month}/${payout.exp_year}` : null,
        }

        return (
          <div className={'flex flex-row gap-3'} key={i}>
            <span className={'textLink'} onClick={() => navigator.clipboard.writeText(data.id)}>
              ID
            </span>
            <span>{data.type}</span>
            <span>{data.name}</span>
            <span>{data.last4}</span>
            {data.exp && <span>{data.exp}</span>}
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
