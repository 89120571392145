import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import { formatMoney } from '../../../utils/utils'

export const CompoundDetailsSection = ({ contract }) => {
  const details = contract?.InterestDetails?.CompoundDetails || []
  const lastCompoundDate = contract?.InterestDetails?.LastCompoundDate

  return (
    <SimpleSectionCard
      header={'Compounding Details'}
      subHeader={`Last Compound Date: ${lastCompoundDate ? new Date(lastCompoundDate * 1000).toDateString() : 'na'}`}
      detailsClassName={'pt-2 gap-2 flex flex-col'}
      containerClassName={'h-max'}
    >
      {!details.length ? 'Contract has not been compounded yet' : null}

      {[...details].reverse()?.map((payment, i) => {
        return (
          <div className={'flex flex-row gap-3 hover:bg-gray-200'} key={i}>
            <span>D.: {new Date(payment.CompoundDate * 1000).toDateString()}</span>
            <span>BB: {formatMoney(payment.BeginningBalance)}</span>
            <span>EB: {formatMoney(payment.EndingBalance)}</span>
            <span>IA: {formatMoney(payment.InterestAccrued)}</span>
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
