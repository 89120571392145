import { Layout } from '../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchTierById, selectAllTiersMap } from '../../redux/slices/tiersSlice'
import { useAppDispatch } from '../../redux/store'
import { DisplayTierDetails } from './components/DisplayTierDetails/DisplayTierDetails'
import { EditModeView } from './components/EditModeView/EditModeView'

export const SubscriptionTierDetailsPage = () => {
  const params: any = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const allTiers = useSelector(selectAllTiersMap)
  const [tier, setTier] = useState<any>(null)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (params.id === 'new') {
      // do nothing
    } else if (!isNaN(parseInt(params.id))) {
      dispatch(fetchTierById(params.id))
    } else {
      navigate('/subscription-tiers')
    }

    //eslint-disable-next-line
  }, [params.id])

  useEffect(() => {
    if (allTiers[params.id]) {
      setTier(allTiers[params.id])
    }

    //eslint-disable-next-line
  }, [allTiers])

  const handleCancelEditMode = () => {
    if (editMode) {
      setEditMode(false)
    } else {
      navigate('/subscription-tiers')
    }
  }

  return (
    <Layout>
      <div className={'flex justify-between items-center'}>
        <h1>Subscription Tier Details</h1>
      </div>

      {params.id === 'new' || editMode ? (
        <EditModeView tierVersions={tier} onCancelEditMode={handleCancelEditMode} />
      ) : null}

      {tier && !editMode ? <DisplayTierDetails tierId={params.id} handleEditClick={() => setEditMode(true)} /> : null}
    </Layout>
  )
}
