import { configureStore } from '@reduxjs/toolkit'

import usersSlice from './slices/usersSlice'
import contractsSlice from './slices/contractsSlice'
import tiersSlice from './slices/tiersSlice'
import appSlice from './slices/appSlice'
import { useDispatch } from 'react-redux'

const reducer = {
  usersSlice,
  contractsSlice,
  appSlice,
  tiersSlice,
}

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === 'development',
})

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export default store
