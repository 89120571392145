import { Box, Card, Fab } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAllContracts } from '../../../redux/slices/contractsSlice'
import LabelAndValue from '../../../components/LabelAndValue/LabelAndValue'
import { getActivePremiumLoansPaymentTypeBucket } from '../../../utils/contractStats'

export const TierDisplay = ({ tier }) => {
  const firstVersion = tier.versions[tier.versions.length - 1]
  const latestVersion = tier.versions[0]
  const navigate = useNavigate()
  const allContracts = useSelector(selectAllContracts)

  const paymentTypes = getActivePremiumLoansPaymentTypeBucket(
    allContracts.filter((c) => c.TierDetails.TierId === parseInt(tier.id) && c.ContractStatus === 'Active')
  )

  return (
    <Card className={'p-4 max-w-[500px]'}>
      <Box display={'flex'} justifyContent={'space-between'} flexDirection={'row'}>
        <Box>
          <h2 className={'mt-0'}>{latestVersion.Name}</h2>

          <LabelAndValue label={'Created At'} value={new Date(firstVersion.CreatedAt * 1000).toLocaleDateString()} />
          <LabelAndValue
            label={'Total Contracts'}
            value={
              allContracts.filter(
                (c) => c.TierDetails.TierId === parseInt(tier.id) && c.TierDetails.PaymentStatus !== 'required'
              ).length
            }
          />
          <LabelAndValue
            label={'Active Contracts'}
            value={
              allContracts.filter((c) => c.TierDetails.TierId === parseInt(tier.id) && c.ContractStatus === 'Active')
                .length
            }
          />
          {Object.keys(paymentTypes).map((k) => (
            <LabelAndValue key={k} label={paymentTypes[k].split(' - ')[0]} value={paymentTypes[k].split(' - ')[1]} />
          ))}
        </Box>

        <Fab color={'success'} size={'small'} onClick={() => navigate(`/subscription-tiers/${tier.id}`)}>
          <EditIcon />
        </Fab>
      </Box>
    </Card>
  )
}
