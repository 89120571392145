import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import { formatMoney, getStripeRedirect } from '../../../utils/utils'
import classes from './Sections.module.scss'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Chip } from '@mui/material'

export const UserDepositsSection = ({ loans = [], userEmail }) => {
  const [payments, setPayments] = useState<any[]>([])

  useEffect(() => {
    const temp: any[] = []

    loans.forEach((contract: any) => {
      const idsSet = new Set()
      contract.StripePaymentsQueue.forEach((payment) => {
        idsSet.add(payment.id)
        temp.push({
          id: payment.id,
          amount: payment.totalTransaction,
          sentOn: payment.date,
          type: payment.stage,
          source: payment.type,
          recipient: payment.recipient,
          isPending: true,
          senderEmail: payment.sender,
          contractId: contract.ContractId,
        })
      })

      contract.FreebirdQueue.forEach((payment) => {
        idsSet.add(payment.id)
        temp.push({
          id: payment.id,
          amount: payment.amount,
          sentOn: payment.date,
          type: payment.stage,
          source: `freebird - ${payment.medium}`,
          recipient: payment.recipient,
          isPending: true,
          senderEmail: payment.sender,
          contractId: contract.ContractId,
        })
      })

      contract?.Payments?.forEach((payment) => {
        if (!idsSet.has(payment.Identifier)) {
          temp.push({
            id: payment.Identifier,
            amount: payment.Amount,
            sentOn: payment.Date,
            type: payment.Stage,
            source: payment.Type === 'freebird' ? `${payment.Type} - ${payment.Medium}` : payment.Type,
            recipient: payment.Recipient,
            isPending: false,
            senderEmail: payment.Sender,
            contractId: contract.ContractId,
          })
        }
      })
    })

    temp.sort((a, b) => new Date(b.sentOn).getTime() - new Date(a.sentOn).getTime())

    setPayments(temp)
  }, [loans])

  return (
    <SimpleSectionCard
      header={'Deposits'}
      subHeader={'Payments this user has received'}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
      action={
        <label>
          Total Deposits:{' '}
          <b>
            {formatMoney(
              payments
                .filter((p) => p.recipient === userEmail && !p.isPending)
                .reduce((acc, p) => acc + parseFloat(p.amount), 0)
            )}
          </b>
        </label>
      }
    >
      {!payments?.length ? 'User made no payments' : null}

      {payments
        ?.filter((p) => p.recipient === userEmail)
        .map((payment: any, i) => {
          return (
            <div className={'flex flex-row gap-3 hover:bg-gray-200'} key={i}>
              <span className={'textLink'} onClick={() => navigator.clipboard.writeText(payment.id)}>
                ID
              </span>
              <Link to={`/contracts/${payment.contractId}`} className={`${classes.sectionField} textLink`}>
                {payment.contractId}
              </Link>
              <span className={`${classes.sectionField}`}>{formatMoney(payment.amount)}</span>
              <Link to={`/users/${payment.senderEmail}`} className={`${classes.sectionFieldLarge} textLink`}>
                <i>from:</i> {payment.senderEmail}
              </Link>
              <span
                className={`${classes.sectionFieldLarge} ${!payment.source.includes('freebird') && 'exteriorLink'}`}
                onClick={() => (!payment.source.includes('freebird') ? getStripeRedirect(payment) : null)}
              >
                {payment.source}
              </span>
              <span className={`${classes.sectionField}`}>{new Date(payment.sentOn * 1000).toLocaleDateString()}</span>
              <span>{payment.isPending ? <Chip label="pending" size="small" color={'warning'} /> : ''}</span>
            </div>
          )
        })}
    </SimpleSectionCard>
  )
}
