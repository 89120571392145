import { SimpleSectionCard } from '../../../components/SectionCards/SimpleSectionCard/SimpleSectionCard'
import LabelAndValue from '../../../components/LabelAndValue/LabelAndValue'
import { formatMoney } from '../../../utils/utils'
import { TPigeonContract } from '../../../typescript/TPigeonContract'

type Props = {
  contract: TPigeonContract
}

export const CustomRepaymentsScheduleSection = ({ contract }: Props) => {
  const schedule = contract.CustomRepaymentSchedule

  let currentMonthIndex: number = schedule.length - 1

  for (let i in schedule) {
    const [, hasPast] = schedule[i]
    if (!hasPast) {
      currentMonthIndex = parseInt(i) === 0 ? 0 : parseInt(i) - 1
      break
    }
  }

  return (
    <SimpleSectionCard
      header={'Custom Repayment Schedule'}
      subHeader={'Monthly payments determined by the users'}
      detailsClassName={'pt-2 gap-1 flex flex-col'}
      containerClassName={'h-max'}
    >
      {schedule?.map((payment, i) => {
        const suffix = i === currentMonthIndex ? ' - current' : i < currentMonthIndex ? ' - past' : ''
        return (
          <div className={'flex flex-row gap-3 hover:bg-gray-200'} key={i}>
            <LabelAndValue label={`month ${i + 1}`} value={`${formatMoney(payment[0])}${suffix}`} />
          </div>
        )
      })}
    </SimpleSectionCard>
  )
}
