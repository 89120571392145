import { useEffect } from 'react'
import { useAppDispatch } from '../../redux/store'
import { fetchAllCognitoUsers, fetchAllUsers, selectAllReferrals, selectAllUsers } from '../../redux/slices/usersSlice'
import { useSelector } from 'react-redux'
import { fetchAllContracts, selectAllContracts } from '../../redux/slices/contractsSlice'
import { ValueCardDisplay } from '../../components/ValueCardDisplay'
import { Box } from '@mui/system'
import { Layout } from '../../components/Layout/Layout'
import { selectDidInitiallyDownload, setInitialDataDownload, setLastDataRefresh } from '../../redux/slices/appSlice'
import {
  getDemographicBuckets,
  getHeardAboutUsBuckets,
  getLast24HoursActiveUsersCount,
  getMonthlyActiveUsersCount,
  getMonthlyReturningActiveUsersCount,
  getNumberActiveLoanBuckets,
} from '../../utils/userStats'
import {
  getActivePremiumLoans,
  getActivePremiumLoansPayerBucket,
  getActivePremiumLoansPaymentTypeBucket,
  getActivePremiumLoansTiersBucket,
  getAllLoansActivated,
  getAllPremiumLoans,
  getAverageLoanDuration,
  getAverageLoanInterestRate,
  getAveragePrincipal,
  getContractsByStatus,
  getLoanCancelReasons,
  getLoanCreatedReasons,
  getLoanPrincipalBuckets,
  getLoansInDefault,
  getTotalMoneyMoved,
} from '../../utils/contractStats'
import { formatMoney } from '../../utils/utils'
import { ListCardDisplay } from '../../components/ListCardDisplay'
import Masonry from '@mui/lab/Masonry'
import { fetchAllTiers, selectAllTiersMap } from '../../redux/slices/tiersSlice'

export const DashboardPage = () => {
  const dispatch = useAppDispatch()
  const allUsers: any = useSelector(selectAllUsers)
  const allOpenReferrals: any = useSelector(selectAllReferrals)
  const allContracts: any = useSelector(selectAllContracts)
  const initialDataLoad = useSelector(selectDidInitiallyDownload)
  const allTiers = useSelector(selectAllTiersMap)

  const usersStats = [
    {
      label: 'Total Users',
      callback: () => allUsers.length,
      component: ValueCardDisplay,
      props: {
        description: 'All users that have a cognito account as well as pigeon data',
      },
    },
    {
      label: 'Last 24 hours Active Users',
      callback: () => getLast24HoursActiveUsersCount(allUsers),
      component: ValueCardDisplay,
      props: {
        description: 'Users that have logged in at least once in the last 24 hours',
      },
    },
    {
      label: 'Monthly Active Users',
      callback: () => getMonthlyActiveUsersCount(allUsers),
      component: ValueCardDisplay,
      props: {
        description: 'Users that have logged in at least once in the last 30 days',
      },
    },
    {
      label: 'Returning Monthly Active Users',
      callback: () => getMonthlyReturningActiveUsersCount(allUsers),
      component: ValueCardDisplay,
      props: {
        description:
          'Users that have logged in at least once within the last month and are at least 30 days apart from the date of creation',
      },
    },
    {
      label: 'Contract Referrals',
      callback: () => allOpenReferrals.length,
      component: ValueCardDisplay,
      props: {
        description: 'These are the number of users that were sent a loan offer and have not yet signed up.',
      },
    },
    {
      label: 'Active loans to users',
      callback: () => getNumberActiveLoanBuckets(allUsers),
      component: ListCardDisplay,
      props: {
        hideIndex: true,
        description: (
          <span>
            Counted how many loans each user has, then added 1 to each respective bucket. <br />
            <br /> ex) If a user has exactly 2 active loans, 1 is added to the bucket of 2 loans.
          </span>
        ),
      },
    },
    {
      label: 'Gender',
      callback: () => getDemographicBuckets(allUsers, 'custom:gender'),
      component: ListCardDisplay,
      props: {
        hideIndex: true,
        description: (
          <span>
            Only shows for people who have voluntarily reported.
            <br />
            <br />
            {allUsers.filter((u) => u?.['custom:gender']).length} answered out of {allUsers.length}
            <br />
            <br />
          </span>
        ),
      },
    },
    {
      label: 'Income Range',
      callback: () => getDemographicBuckets(allUsers, 'custom:personalIncome'),
      component: ListCardDisplay,
      props: {
        hideIndex: true,
        description: (
          <span>
            Only shows for people who have voluntarily reported.
            <br />
            <br />
            {allUsers.filter((u) => u?.['custom:personalIncome']).length} answered out of {allUsers.length}
            <br />
            <br />
          </span>
        ),
      },
    },
    {
      label: 'Ethnicity',
      callback: () => getDemographicBuckets(allUsers, 'custom:ethnicity'),
      component: ListCardDisplay,
      props: {
        hideIndex: true,
        description: (
          <span>
            Only shows for people who have voluntarily reported.
            <br />
            <br />
            {allUsers.filter((u) => u?.['custom:ethnicity']).length} answered out of {allUsers.length}
            <br />
            <br />
          </span>
        ),
      },
    },
    {
      label: 'Credit Range',
      callback: () => getDemographicBuckets(allUsers, 'CreditRange'),
      component: ListCardDisplay,
      props: {
        hideIndex: true,
        description: (
          <span>
            Only shows for people who have voluntarily reported.
            <br />
            <br />
            {allUsers.filter((u) => u?.['CreditRange']).length} answered out of {allUsers.length}
            <br />
            <br />
          </span>
        ),
      },
    },
    {
      label: 'Heard About Us',
      callback: () => getHeardAboutUsBuckets(allUsers),
      component: ListCardDisplay,
      props: {
        hideIndex: true,
        description: <span>Only shows for users who have answered.</span>,
      },
    },
  ]

  const contractsStats = [
    {
      label: 'Total Contracts',
      callback: () => allContracts.length,
      component: ValueCardDisplay,
      props: {
        description: 'All contracts',
      },
    },
    {
      label: 'Total Active Loans',
      callback: () => getContractsByStatus('Active', allContracts).length,
      component: ValueCardDisplay,
      props: {
        description: 'All loans that have the "Active" status',
      },
    },
    {
      label: 'Loans In Default',
      callback: () => getLoansInDefault(allContracts).length,
      component: ValueCardDisplay,
      props: {
        description:
          'All loans that have the "Active" status, have 0 remaining months left, and have not been extended',
      },
    },
    {
      label: 'Total Loans Activated',
      callback: () => getAllLoansActivated(allContracts).length,
      component: ValueCardDisplay,
      props: {
        description:
          'All loans that have the "Active" status AND "Inactive" status where the lender and borrower have accepted. By both the lender and borrower accepting, the loan must have once been active.',
      },
    },
    {
      label: 'Active Premium Loans',
      callback: () => getActivePremiumLoans(allContracts).length,
      component: ValueCardDisplay,
      props: {
        description: 'All loans that have the "Active" status AND don\'t have the free tier.',
      },
    },
    {
      label: 'Total Premium Loans',
      callback: () => getAllPremiumLoans(allContracts).length,
      component: ValueCardDisplay,
      props: {
        description:
          'All loans that have the "Active" status or was active at one point AND don\'t have the free tier.',
      },
    },
    {
      label: 'Current Money Managed',
      callback: () => formatMoney(getTotalMoneyMoved(allContracts, false)),
      component: ValueCardDisplay,
      props: {
        size: 'sm',
        description: 'The Sum all principals for active contracts',
      },
    },
    {
      label: 'Total Money Managed',
      callback: () => formatMoney(getTotalMoneyMoved(allContracts, true)),
      component: ValueCardDisplay,
      props: {
        size: 'sm',
        description: (
          <span>
            The Sum all principals for active and once active contracts.
            <br />
            <br /> "Once active" contracts are Inactive contracts that have been accepted by both lender and borrower
          </span>
        ),
      },
    },
    {
      label: 'Pending Loans',
      callback: () => getContractsByStatus('Pending', allContracts).length,
      component: ValueCardDisplay,
      props: {
        description: 'Total number of contracts with the "Pending" status',
      },
    },
    {
      label: 'Average Principal (active)',
      callback: () => formatMoney(getAveragePrincipal(allContracts)),
      component: ValueCardDisplay,
      props: {
        description: 'The average principal of all currently active contracts.',
      },
    },
    {
      label: 'Average Duration (months)',
      callback: () => getAverageLoanDuration(allContracts),
      component: ValueCardDisplay,
      props: {
        description: 'The average duration in months of all currently active contracts.',
      },
    },
    {
      label: 'Average Interest Rate',
      callback: () => getAverageLoanInterestRate(allContracts) + '%',
      component: ValueCardDisplay,
      props: {
        description: 'The average interest rate of all currently active contracts.',
      },
    },
    {
      label: 'Loan Creation Reasons',
      callback: () => getLoanCreatedReasons(allContracts),
      component: ListCardDisplay,
      props: {
        description: 'Reason for creating loans for ALL loans on our platform. ',
      },
    },
    {
      label: 'Loan Principal buckets',
      callback: () => getLoanPrincipalBuckets(allContracts),
      component: ListCardDisplay,
      props: {
        description: 'Loan principal ranges and the number of currently active loans that fall into that',
      },
    },
    {
      label: 'Loan Cancel Reasons',
      callback: () => getLoanCancelReasons(allContracts),
      component: ListCardDisplay,
      props: {
        description: (
          <span>
            Reason for canceling loans for ALL loans on our platform.
            <br />
            <br /> "unknown" reason is loans that were canceled before we began tracking the cancel reason.
          </span>
        ),
      },
    },
    {
      label: 'Premium Loan Payment Type',
      callback: () => getActivePremiumLoansPaymentTypeBucket(allContracts),
      component: ListCardDisplay,
      props: {
        description: (
          <span>
            This stat shows the payment type for all premium loans, including those that were once active.
            <br />
          </span>
        ),
      },
    },
    {
      label: 'Premium Loan Tiers',
      callback: () => getActivePremiumLoansTiersBucket(allContracts, allTiers),
      component: ListCardDisplay,
      props: {
        description: (
          <span>
            This stat shows the tiers for all premium loans, including those that were once active.
            <br />
          </span>
        ),
      },
    },
    {
      label: 'Premium Loan Payers',
      callback: () => getActivePremiumLoansPayerBucket(allContracts),
      component: ListCardDisplay,
      props: {
        description: (
          <span>
            This stat shows who paid for the premium loans, including those that were once active.
            <br />
          </span>
        ),
      },
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchAllContracts()).unwrap(),
        dispatch(fetchAllUsers()).unwrap(),
        dispatch(fetchAllCognitoUsers()).unwrap(),
        dispatch(fetchAllTiers()).unwrap(),
      ])
      dispatch(setLastDataRefresh(null))
      dispatch(setInitialDataDownload(null))
    }

    if (!initialDataLoad) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <Box className={'flex flex-col gap-4 p-2 pb-10 container mx-auto'}>
        <div>
          <h1>Statistics:</h1>
          <div className={'ml-10'}>
            <h2>Users</h2>
            <Box sx={{ width: 1300 }}>
              <Masonry columns={2} spacing={1}>
                {usersStats.map((stat, index) => {
                  let Component: any = stat.component
                  return (
                    <Component key={index} label={stat.label} value={stat.callback()} size={'lg'} {...stat.props} />
                  )
                })}
              </Masonry>
            </Box>

            <h2 className={'mt-8'}>Contracts</h2>
            <Box sx={{ width: 1300 }}>
              <Masonry columns={3} spacing={1}>
                {contractsStats.map((stat, index) => {
                  let Component: any = stat.component
                  return (
                    <Component key={index} label={stat.label} value={stat.callback()} size={'lg'} {...stat.props} />
                  )
                })}
              </Masonry>
            </Box>
          </div>
        </div>
      </Box>
    </Layout>
  )
}
